import { Button, Link, Typography } from "@mui/material";
import { darken } from "@mui/material/styles";
import React from "react";
import * as Router from "react-router-dom";

export default function SubCategoryTile(props) {
  return (
    <Button
      sx={{
        display: "flex",
        justifyContent: "center",
        flexGrow: 1,
        alignContent: "baseline",
        minHeight: props.height ?? "1rem",
        minWidth: props.minWwidth ?? "2rem",
        padding: "1rem",
        boxShadow: "none",
        backgroundColor: (theme) => darken(theme.palette.secondary.main, 0.05)
      }}>
      <Link
        component={Router.Link}
        to={`/subcategory/${props.item.name}/${props.item.id}`}
        underline="none"
        sx={{
          textDecoration: "none",
          boxShadow: "none",
          alignSelf: "end",
          paddingLeft: "0.5rem"
        }}>
        <Typography
          sx={{
            textTransform: "none",
            fontSize: "1rem"
          }}>
          {props.item.name}
        </Typography>
      </Link>
    </Button>
  );
}
