import ShippingInfoAddingWayForm from "../ShippingInfoAddingWayForm";

function ShippingInfoAddingWay(props) {
  return (
    <ShippingInfoAddingWayForm
      shippingInfoAddingWay={props.shippingInfoAddingWay}
      setShippingInfoAddingWay={props.setShippingInfoAddingWay}
    />
  );
}
export default ShippingInfoAddingWay;
