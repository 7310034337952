import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AppProviders from "./context/AppProviders";
import Account from "./pages/Account";
import Category from "./pages/Category";
import Home from "./pages/Home";
import ProductManagement from "./pages/ProductManagement";
import ProductPage from "./pages/ProductPage";
import Search from "./pages/Search";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Wishlist from "./pages/Wishlist";
import Seller from "./pages/Seller";
import UpdatedCheckout from "./pages/UpdatedCheckout";
import OrderConfirmation from "./pages/OrderConfirmation";
import Subcategory from "./pages/Subcategory";
import { Box, Typography } from "@mui/material";
import { ScrollToTop } from "./utils/ScrollToTop";
import { useUserContext } from "./hooks/userHooks";

function App() {
  const ProtectedRoute = ({ children }) => {
    const { isLoggedIn } = useUserContext();
    if (!isLoggedIn) {
      return <Navigate to="/signin" replace />;
    }

    return children;
  };

  return (
    <AppProviders>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route
            path="seller"
            element={
              <ProtectedRoute>
                <Seller />
              </ProtectedRoute>
            }
          />
          <Route path="checkout" element={<UpdatedCheckout />} />
          <Route
            path="confirmation"
            element={
              <ProtectedRoute>
                <OrderConfirmation />
              </ProtectedRoute>
            }
          />
          <Route path="category">
            <Route path=":categoryName/:categoryId" element={<Category />} />
          </Route>
          <Route path="subcategory">
            <Route path=":categoryName/:categoryId" element={<Subcategory />} />
          </Route>
          <Route path="search">
            <Route path=":search" element={<Search />} />
          </Route>
          <Route path="product">
            <Route path=":id" element={<ProductPage />} />
          </Route>
          <Route
            path="account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
          <Route
            path="wishlist"
            element={
              <ProtectedRoute>
                <Wishlist />
              </ProtectedRoute>
            }
          />
          <Route
            path="sellerproducts"
            element={
              <ProtectedRoute>
                <ProductManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="createproduct"
            element={
              <ProtectedRoute>
                <Seller />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <Box
                  sx={{
                    paddingTop: "30vh"
                  }}>
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    sx={{ height: "2rem", textAlign: "center" }}>
                    Take the wrong turn?
                  </Typography>
                </Box>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </AppProviders>
  );
}

export default App;
