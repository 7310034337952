import { useState, useEffect } from "react";

/**
 * Provides window size as state.
 * @returns Current Window Size.
 */
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    windowWidth: 1,
    windowHeight: 1
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

/**
 * Provides width of a given element as state.
 * @param {String} id DOM element ID
 * @returns element width.
 */
export function useElementWidth(id) {
  const element = document.getElementById(id);
  const elemWidth = element?.clientWidth;
  const [width, setWidth] = useState(
    elemWidth === undefined || elemWidth <= 0 ? 1000 : elemWidth
  );
  useEffect(() => {
    function handleResize() {
      let newElemWidth = element?.clientWidth;
      setWidth(
        newElemWidth === undefined || newElemWidth <= 0 ? 1000 : newElemWidth
      );
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [element?.clientWidth]);
  return width;
}
