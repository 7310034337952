export const NODE_ENV = process.env.NODE_ENV || "DEVELOPMENT";
export const MAX_FILE_SIZE = 6000000;
export const PRODUCT_MAX_MEDIA_FILES = 5;
export const UPLOADED_FILES_NODE_ID =
  process.env.UPLOADED_FILES_NODE_ID || "0000018b3bd4a7d4-8e51883a5d890000";
export const APP_NAME = "Basic Needs";
export const PRODUCT_MESSAGE_STATUS_ADDED = "added";
export const PRODUCT_MESSAGE_STATUS_DELETED = "deleted";
export const VIDEO_FRAME_WIDTH = 256;
export const VIDEO_FRAME_HEIGHT = 144;
export const VIDEO_FRAME_FORMAT = "jpeg";
export const VIDEO_FRAME_QUALITY = 0.8;
export const MAX_WINDOW_WIDTH = 900;
export const LOGIN_SSO_URL =
  process.env.LOGIN_SSO_URL || "https://app.ilivemylife.io";
export const LOGIN_SSO_ROUTE = process.env.LOGIN_SSO_ROUTE || "basicNeedsLogin";
export const SIGNUP_SSO_ROUTE =
  process.env.SIGNUP_SSO_ROUTE || "basicNeedsSignup";
export const LOGIN_SSO_WINDOW_NAME =
  process.env.LOGIN_SSO_WINDOW_NAME || "LoginPopup";
export const LOGIN_SSO_WINDOW_WIDTH =
  process.env.LOGIN_SSO_WINDOW_WIDTH || "500";
export const LOGIN_SSO_WINDOW_HEIGHT =
  process.env.LOGIN_SSO_WINDOW_HEIGHT || "400";
export const SIGNUP_SSO_WINDOW_HEIGHT =
  process.env.SIGNUP_SSO_WINDOW_HEIGHT || "600";
export const LOGIN_SSO_RESPONSE_TYPE =
  process.env.LOGIN_SSO_RESPONSE_TYPE || "loginSuccess";

export const MAX_PRODUCT_VISIBLE_DESCRIPTION_LENGHT = 250;
export const MAX_PRODUCT_DESCRIPTION_LENGHT = 2500;
export const MAX_PRODUCT_TITLE_LENGHT = 250;

export const MAX_MOBILE_WIDTH = 500;
export const CART_PADDING_LEFT = 10;
export const CART_PADDING_RIGHT = 10;
