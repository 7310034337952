import { SwipeableDrawer } from "@mui/material";

export default function agedrawPageDrawer(props) {
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    props.setOpen(open);
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={props.direction}
        open={props.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ overflow: "hidden" }}>
        {props.children}
      </SwipeableDrawer>
    </div>
  );
}
