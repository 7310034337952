import { useWindowSize, useElementWidth } from "../../hooks/uiHooks";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { MAX_WINDOW_WIDTH } from "../../constants/";

function ProductImage(props) {
  const { windowWidth } = useWindowSize();
  const productViewSize = useElementWidth("product-view");
  if (windowWidth > MAX_WINDOW_WIDTH) {
    return (
      <InnerImageZoom src={props.uri} width={productViewSize} zoomScale={2} />
    );
  }

  return (
    <Zoom>
      <img src={props.uri} width={windowWidth - 50} alt="product" />
    </Zoom>
  );
}

export default ProductImage;
