import React from "react";
import { Grid, TextField } from "@mui/material";
import "../global.css";

function ShippingForm(props) {
  console.log("ShippingForm:");
  console.log(props);
  return (
    <div className="shipping-form">
      <div style={styles.header}>
        <h1 style={styles.headerTitle}>Shipping Information</h1>
      </div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <TextField
              label="First Name"
              fullWidth
              value={props.shippingInformation.firstName}
              onChange={(e) => {
                props.setShippingInformation({
                  ...props.shippingInformation,
                  firstName: e.nativeEvent.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              label="Last Name"
              fullWidth
              value={props.shippingInformation.lastName}
              onChange={(e) => {
                props.setShippingInformation({
                  ...props.shippingInformation,
                  lastName: e.nativeEvent.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              fullWidth
              value={props.shippingInformation.email}
              onChange={(e) => {
                props.setShippingInformation({
                  ...props.shippingInformation,
                  email: e.nativeEvent.target.value
                });
              }}
              validator="isEmail"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address line"
              value={props.shippingInformation.address}
              fullWidth
              onChange={(e) => {
                props.setShippingInformation({
                  ...props.shippingInformation,
                  address: e.nativeEvent.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={8} sm={6}>
            <TextField
              label="Postal/Zip Code"
              value={props.shippingInformation.postalCode}
              fullWidth
              onChange={(e) => {
                props.setShippingInformation({
                  ...props.shippingInformation,
                  postalCode: e.nativeEvent.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={8} sm={6}>
            <TextField
              label="City"
              value={props.shippingInformation.city}
              fullWidth
              onChange={(e) => {
                props.setShippingInformation({
                  ...props.shippingInformation,
                  city: e.nativeEvent.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={8} sm={6}>
            <TextField
              label="Province/State"
              value={props.shippingInformation.province}
              fullWidth
              onChange={(e) => {
                props.setShippingInformation({
                  ...props.shippingInformation,
                  province: e.nativeEvent.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={8} sm={6}>
            <TextField
              label="Country"
              value={props.shippingInformation.country}
              fullWidth
              onChange={(e) => {
                props.setShippingInformation({
                  ...props.shippingInformation,
                  country: e.nativeEvent.target.value
                });
              }}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
const styles = {
  payButtonDiv: {
    display: "flex",
    padding: "20px",
    width: "65%",
    marginBottom: "20px",
    alignSelf: "flex-end",
    justifyContent: "flex-end",
    marginRight: "-15px"
  },
  payButton: {
    backgroundColor: "#FF9900",
    width: "75%",
    alignSelf: "center"
  },
  header: {
    display: "flex"
  },
  headerTitle: {
    width: "65%"
  }
};

export default ShippingForm;
