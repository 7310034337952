import SignInForm from "../components/Authentication/SignInForm";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../hooks/userHooks";

export default function SignIn() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { isLoggedIn } = useUserContext();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div>
      <SignInForm returnUrl={params.get("return-url")} />
    </div>
  );
}
