import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { alpha } from "@mui/material/styles";
import * as Router from "react-router-dom";

export default function MenuDrawerItem({ url, name }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        width: "100%",
        height: "3rem",
        background: "none",
        boxShadow: "none",
        ":hover": {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          cursor: "pointer"
        }
      }}>
      <Link
        component={Router.Link}
        to={`/${url}`}
        underline="hover"
        sx={{
          display: "flex",
          alignContent: "center",
          width: "100%",
          borderTop: "1px solid",
          borderColor: (theme) => alpha(theme.palette.tertiary.main, 0.1),
          ":first-of-type": {
            borderTop: "none"
          }
        }}>
        <Typography
          sx={{
            display: "flex",
            alignContent: "center",
            padding: "1rem 2rem 0 1rem",
            marginBottom: "0"
          }}>
          {name}
        </Typography>
      </Link>
    </Box>
  );
}
