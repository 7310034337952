import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { createRef, useEffect, useState } from "react";
import { CategoryContext, HomeContext } from "../context/Contexts";
import { CategoriesService } from "../services/api/general";
import { UserProvider } from "./UserProvider";

/**
 * MUI theme provider.
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#F8F8F8",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#3E3E3E",
      contrastText: "#FFFFFF"
    },
    tertiary: {
      main: "#FF9900"
    },
    warning: {
      main: "#FFFFFF"
    },
    danger: {
      main: "#FD0000"
    },
    lightText: {
      main: "#FFFFFF"
    },
    darkText: {
      main: "#3E3E3E"
    }
  }
});

/**
 * Provides Context and Config to App.
 */
export default function AppProviders(props) {
  const [categories, setCategories] = useState({ all: [], top: [] });
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);
  const notistackRef = createRef();

  useEffect(() => {
    CategoriesService._getCategories()
      .then((data) => setCategories({ all: data.all, top: data.top }))
      .catch((err) => {
        throw new Error("Failed to load category data", err);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <CategoryContext.Provider value={[categories, setCategories]}>
          <HomeContext.Provider
            value={[categoriesWithProducts, setCategoriesWithProducts]}>
            <SnackbarProvider
              maxSnack={5}
              ref={notistackRef}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              action={(key) => (
                <Button onClick={() => notistackRef.current.closeSnackbar(key)}>
                  <ClearIcon />
                </Button>
              )}>
              {props.children}
            </SnackbarProvider>
          </HomeContext.Provider>
        </CategoryContext.Provider>
      </UserProvider>
    </ThemeProvider>
  );
}
