import { styled } from "@mui/material/styles";

export const CartItemContainer = styled("div")(({ theme }) => ({
  position: "relative",
  maxHeight: "100%",
  height: "auto",
  color: "inherit",
  fontSize: "auto"
}));

export const Thumbnail = styled("img")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "block",
  maxWidth: "70px",
  maxHeight: "100%",
  width: "auto",
  height: "auto",
  margin: "auto 0"
}));

export const ProdName = styled("p")(({ theme }) => ({
  display: "flex",
  flexWrap: "none",
  fontSize: "1.5rem",
  overflow: "hidden",
  margin: 0
}));

export const ProdPrice = styled("p")(({ theme }) => ({
  fontSize: "1rem",
  marginTop: 0
}));

export const ProdDetail = styled("p")(({ theme }) => ({
  margin: 0
}));

export const CenteredDiv = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "center"
}));
