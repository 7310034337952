/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import MainContainer from "../components/MainContainer";
import Header from "../components/NavigationBar/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "../components/DetailsModal";
import { OrdersService } from "../services/api/general";
import ReactLoading from "react-loading";

function Account() {
  const [buyersOrders, setBuyersOrders] = useState([]);
  const [done, setDone] = useState(false);
  useEffect(() => {
    setDone(false);
    OrdersService._getOrdersByBuyer()
      .then((res) => {
        let buyersOrdersInformation = [];
        for (let k = 0; k < res.length; k = k + 1) {
          let products = [];
          // let productSubtotal = 0;
          for (let i = 1; i < res[k].products.length; i = i + 1) {
            let productObject = JSON.parse(
              res[k].products[i].description
            ).product;

            products.push({
              image: productObject.picture,
              name: productObject.name,
              sellerName: productObject.seller,
              status: JSON.parse(res[k].products[i].description).fulfilled,
              quantity: JSON.parse(res[k].products[i].description).qty,
              price: productObject.price,
            });
            // productSubtotal = productSubtotal
            console.log("Product INfor: \n\n", products);
            console.log(
              "QUANT; ",
              JSON.parse(res[k].products[i].description).qty
            );
          }

          buyersOrdersInformation.push({
            confirmationNumber: res[k].id,
            // confirmationNumber: res[k].id,
            date: new Date(JSON.parse(res[k].description).date).toDateString(),
            name: JSON.parse(res[k].description).name,
            // subtotal: JSON.parse(res[k].description).subtotal,
            subtotal: JSON.parse(res[k].description).subtotal,
            status: res[k].status,
            partiallyFulfilled: res[k].partiallyFulfilled,
            shippingInformation: JSON.parse(res[k].description).shippinginfo,
            products: products,
          });
        }
        console.log("BUYERS ORDER INFO: ", buyersOrdersInformation);
        return buyersOrdersInformation;
      })
      .then((buyersOrdersInformation) => {
        setDone(true);
        setBuyersOrders(buyersOrdersInformation);
      });
  }, []);

  const columns = [
    { id: "orderNumber", label: "Order Number", align: "center" },
    { id: "date", label: "Date", align: "center" },
    {
      id: "name",
      label: "Billed To",
      align: "center",
    },
    {
      id: "subtotal",
      label: "Subtotal",
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "fulfilled",
      label: "Status",
      align: "center",
    },
    {
      id: "details",
      label: "View Details",
      minWidth: 80,
      align: "center",
      backgroundColor: "black",
    },
  ];

  console.log(buyersOrders);
  return (
    <div>
      <Header />
      <MainContainer>
        {!done ? (
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={100}
            width={100}
            className={"loader"}
          />
        ) : (
          <div>
            <h1
              className="order-history-title"
              style={styles.orderHistoryTitle}
            >
              Order History
            </h1>
            <div className="order-history-main" style={styles.orderHistory}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer style={styles.orderHistoryTable}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {buyersOrders.map((row, key) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={key}
                          >
                            <TableCell align={columns[0].align}>
                              {row.confirmationNumber}
                            </TableCell>
                            <TableCell align={columns[1].align}>
                              {row.date}
                            </TableCell>
                            <TableCell align={columns[2].align}>
                              {row.name}
                            </TableCell>
                            <TableCell align={columns[3].align}>
                              ${row.subtotal}
                            </TableCell>
                            <TableCell align={columns[4].align}>
                              {row.status
                                ? "Fulfilled"
                                : row.status && row.partiallyFufilled
                                ? "Partially Fulfilled"
                                : "Pending"}
                            </TableCell>
                            <TableCell align={columns[5].align}>
                              <Modal row={row}>View Details </Modal>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        )}
      </MainContainer>
    </div>
  );
}

const styles = {
  orderHistory: {
    display: "flex",
    padding: "25px",
    justifyContent: "center",
    alignItems: "center",
  },
  orderHistoryTable: {
    height: "auto",
  },
  orderHistoryTitle: {
    padding: "25px",
  },
  tableHeaderRow: {
    backgroundColor: "#d3d3d3",
  },
};

export default Account;
