import ReactPlayer from "react-player/lazy";

function VideoPlayer(props) {
  return (
    <ReactPlayer
      url={props.uri}
      width={`${props.width}px`}
      height={`${props.height}px`}
      controls={props.controls}
    />
  );
}

export default VideoPlayer;
