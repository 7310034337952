import ShippingForm from "../ShippingForm";

function ShippingInfo(props) {
  console.log("ShippingInfo");
  console.log(props);
  return (
    <ShippingForm
      shippingInformation={props.shippingInformation}
      setShippingInformation={props.setShippingInformation}
    />
  );
}
export default ShippingInfo;
