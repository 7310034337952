import Axios from "axios";
import { getAndDecodeToken } from "../../utils/auth";
import { Buffer } from "buffer/";
import {
  UPLOADED_FILES_NODE_ID,
  APP_NAME,
  PRODUCT_MESSAGE_STATUS_ADDED
} from "../../constants/";

const url = process.env.REACT_APP_API_URL;

// This file serves to hold general API methods.
export const MessageService = {
  _addMessage: async (itemId, message) => {
    const response = await Axios.post(`${url}message`, { itemId, message });
    return response;
  },
  _composeMessage: async (uri, status) => {
    const cDate = new Date();
    const timestamp = cDate.getTime();
    return `Project: ${APP_NAME}, Date: ${cDate}, Time: ${timestamp}, Status: ${status}, file: ${uri}`;
  }
};

export const CategoriesService = {
  _getCategories: async () => {
    console.log("_getCategories");
    const categories = await Axios.get(`${url}categories`);
    return { all: categories.data.slice(1), top: categories.data.slice(4, 9) };
  },

  _getSubcategories: async (category) => {
    console.log("_getSubcategories");
    const subCategories = await Axios.get(`${url}item/${category}`);
    if (subCategories.data.length === 0) {
      return null;
    } else {
      let subSection = subCategories.data.slice(1);
      return subSection;
    }
  }
};

export const ProductsService = {
  _uploadFile: async (file) => {
    const form = new FormData();
    const operations = `{
      "operationName":"SingleUpload",
      "variables":{
        "itemId":"${UPLOADED_FILES_NODE_ID}",
        "file":null
      },
      "query":"mutation SingleUpload($itemId: String!, $file: Upload!) {singleUpload(itemId: $itemId, file: $file) {filename mimetype encoding uri}}"
    }`;

    form.append("operations", operations);
    const map = `{"1":["variables.file"]}`;
    form.append("map", map);
    form.append("1", file);

    const requestHeaders = {
      "access-token": getAndDecodeToken(),
      "Apollo-Require-Preflight": "true"
      // "x-apollo-operation-name": "SingleUpload"
    };

    let mediaData = {};
    const endpoint = process.env.REACT_APP_WEB_SERVER_PATH;
    let base64 = null;
    if (file.type.match(/^image\/.*/)) {
      base64 = await convertBase64(file);
    }

    await Axios.post(`${endpoint}/file`, form, {
      headers: requestHeaders
    }).then(async (res) => {
      mediaData = res.data.data.singleUpload;
      const cDate = new Date();
      const millsec = cDate.getMilliseconds();
      mediaData.id = millsec + Math.floor(Math.random() * millsec + 1);
      mediaData.icon = base64;
      const message = await MessageService._composeMessage(
        mediaData.uri,
        PRODUCT_MESSAGE_STATUS_ADDED
      );
      mediaData.message = {
        itemId: UPLOADED_FILES_NODE_ID,
        content: message
      };
    });

    return mediaData;
  },

  _getProductsByCategory: async () => {
    console.log("_getProductsByCategory");
    const categories = await Axios.get(`${url}categories`);
    const categoriesToDisplay = {
      categories: []
    };
    for (var j = 1; j < 5; j++) {
      let categoriesProducts = await getCategoryProducts(categories.data[j]);
      if (categoriesProducts.products.length > 0) {
        categoriesToDisplay.categories.push(categoriesProducts);
      }
    }
    return categoriesToDisplay;
  },

  _getSpecificCategoryProducts: async (name) => {
    console.log("_getSpecificCategoryProducts");
    const products = await Axios.get(`${url}specificcategoryproducts/${name}`);
    console.log("products:");
    console.log(products);
    return products;
  },

  _getCategoriesProducts: async (category) => {
    console.log("_getCategoriesProducts");
    const products = await Axios.get(`${url}category/${category}`);
    const categoryProducts = products.data;
    return categoryProducts;
  },

  _getProduct: async (id) => {
    console.log("_getProduct");
    const product = await Axios.get(`${url}item/${id}`);
    return product.data;
  },

  _searchProducts: async (search) => {
    console.log("_searchProducts");
    const products = await Axios.get(`${url}search/${search}`);
    return products.data;
  },

  _createProduct: async (product) => {
    const media = await updateMedia(product.media);
    if (!media) throw new Error("Failed to create media items");
    const response = await Axios.put(
      `${url}product`,
      {
        name: product.name,
        price: product.price,
        description: product.description,
        subCategoryId: product.subCategoryId,
        media: media
      },
      {
        headers: {
          token: getAndDecodeToken()
        }
      }
    );

    if (response && response.data) {
      product.media.forEach((item) => {
        MessageService._addMessage(
          item.message.itemId,
          `${item.message.content}, itemId: ${response.data.id}`
        );
      });

      await Promise.all(
        product.media.map(async (item) => {
          let status = item.status ?? PRODUCT_MESSAGE_STATUS_ADDED;
          let message = await MessageService._composeMessage(item.uri, status);
          await MessageService._addMessage(
            UPLOADED_FILES_NODE_ID,
            `${message}, itemId: ${response.data.id}`
          );
        })
      );
    }
  },
  _editProduct: async (product) => {
    const media = await updateMedia(product.media);
    if (!!media) product.media = media;
    const response = await Axios.post(
      `${url}edit-product`,
      {
        ...product
      },
      {
        headers: {
          token: getAndDecodeToken()
        }
      }
    );

    if (response && response.data) {
      await Promise.all(
        product.media.map(async (item) => {
          let status = item.status ?? PRODUCT_MESSAGE_STATUS_ADDED;
          let message = await MessageService._composeMessage(item.uri, status);
          await MessageService._addMessage(
            UPLOADED_FILES_NODE_ID,
            `${message}, itemId: ${response.data.id}`
          );
        })
      );
    }
  },
  _deleteProduct: async (id) => {
    console.log("_deleteProduct");
    await Axios.post(
      `${url}delete-product`,
      {
        id
      },
      {
        headers: {
          token: getAndDecodeToken()
        }
      }
    );
  }
};

const getCategoryProducts = async (category) => {
  console.log("getCategoryProducts");
  let categoriesProducts = {
    category: "",
    id: "",
    products: []
  };
  categoriesProducts.category = category.title;
  categoriesProducts.id = category.id;
  let products = await Axios.get(`${url}category/${category.id}`);
  categoriesProducts.products = products.data;
  return categoriesProducts;
};

const updateMedia = async (data) => {
  const media = [];
  for (const item of data) {
    if (item?.frame && (await checkBase64(item.frame))) {
      const image = await decodeBase64Image(item.frame);
      if (image?.data) {
        const form = new FormData();
        form.append("image", image.data, "image.jpg");
        const file = form.get("image");
        const uploadedFile = await ProductsService._uploadFile(file);
        if (!!uploadedFile?.uri) item.frame = uploadedFile.uri;
      }
    }

    media.push({
      filename: item.filename,
      mimetype: item.mimetype,
      uri: item.uri,
      frame: item.frame ?? ""
    });
  }

  return media;
};

const checkBase64 = async (dataStr) => {
  return dataStr.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
};

const convertBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const decodeBase64Image = async (data) => {
  const matches = await checkBase64(data);
  const response = {};

  if (matches.length !== 3) {
    return null;
  }
  response.type = matches[1];
  response.data = Buffer.from(matches[2], "base64");
  response.data = new Blob([response.data], { type: response.type });

  return response;
};

export const CartService = {
  _getCart: async () => {
    let cart = await Axios.get(`${url}cart`, {
      headers: {
        token: getAndDecodeToken()
      }
    });
    let formattedCart = []; // TODO Move formatting to server side?
    for (let i = 0; i < cart.data.length; i++) {
      let fields = JSON.parse(cart.data[i].description);
      let cartProduct = {
        product: {
          description: fields.description,
          id: cart.data[i].id,
          name: fields.name,
          picture: fields.media?.[0].frame || fields.media?.[0].uri, // TODO refactor with productImage
          price: fields.price,
          seller: fields.sellerName,
          subcategoryId: fields.subCategoryId
        },
        qty: cart.data[i].qty
      };
      formattedCart.push(cartProduct);
    }
    return formattedCart;
  },

  // Supports removal (via updating productQty to zero)
  _updateCartItem: async (productId, productQty) => {
    await Axios.put(
      `${url}cart`,
      {
        itemid: productId,
        qty: productQty
      },
      {
        headers: {
          token: getAndDecodeToken()
        }
      }
    );
  },

  // Not used, because we are using _updateCartItem with productQty=0
  _removeFromCart: async (productId) => {
    await Axios.post(
      `${url}cart`,
      {
        itemid: productId
      },
      {
        headers: {
          token: getAndDecodeToken()
        }
      }
    );
  },

  _clearCart: async () => {
    await Axios.delete(`${url}cart`, {
      headers: {
        token: getAndDecodeToken()
      }
    });
  }
};

export const OrdersService = {
  _getOrdersByBuyer: async () => {
    const orders = await Axios.get(`${url}buyersorders`, {
      headers: {
        token: getAndDecodeToken()
      }
    });
    var ordersByBuyer = [];
    for (var j = 1; j < orders.data.length; j++) {
      ordersByBuyer.push(orders.data[j]);
    }

    return ordersByBuyer;
  },

  //Uses the token from basic needs root node if user is not logged in.
  _createOrders: async (purchaseItems, shippingInformation) => {
    const orders = await Axios.post(
      `${url}order`,
      {
        cart: purchaseItems,
        shippingInfo: {
          address: shippingInformation.address,
          city: shippingInformation.city,
          province: shippingInformation.province,
          country: shippingInformation.country,
          postalcode: shippingInformation.postalCode
        },
        email: shippingInformation.email,
        name: shippingInformation.firstName + " " + shippingInformation.lastName
      },
      {
        headers: {
          token: getAndDecodeToken()
        }
      }
    );

    return orders;
  }
};

export const WishlistService = {
  _getUsersWishlist: async () => {
    const wishlist = await Axios.get(`${url}wishlist`, {
      headers: {
        token: getAndDecodeToken()
      }
    });

    let formattedWishlist = []; // TODO refactor with productImage
    for (let i = 0; i < wishlist.data.length; i++) {
      let fields = JSON.parse(wishlist.data[i].description);
      // TODO move formatting to sever side to hide other node's properties from clients in Network traffic
      let wishlistProduct = {
        product: {
          description: fields.description,
          id: wishlist.data[i].id,
          name: fields.name,
          picture: fields.media?.[0].frame || fields.media?.[0].uri, //TODO refactor with productImage
          price: fields.price,
          seller: fields.sellerName,
          subcategoryId: fields.subCategoryId
        }
      };
      formattedWishlist.push(wishlistProduct);
    }
    return formattedWishlist;
  },

  _addToUsersWishlist: async (id) => {
    return Axios.put(
      `${url}wishlist`,
      {
        itemid: id
      },
      {
        headers: {
          token: getAndDecodeToken()
        }
      }
    );
  },

  _removeFromUsersWishlist: async (id) => {
    return Axios.post(
      `${url}wishlist`,
      {
        itemid: id
      },
      {
        headers: {
          token: getAndDecodeToken()
        }
      }
    );
  }
};

export const LoginService = {
  _login: async (email, password) => {
    try {
      const res = await Axios.post(`${url}login`, {
        email: email,
        password: password
      });

      if (res.status !== 200) {
        return null;
      } else {
        return res;
      }
    } catch (err) {
      console.error("Login Error Occurred", err);
      return null;
    }
  },

  _loginSSO: async (data) => {
    try {
      const res = await Axios.post(
        `${url}loginsso`,
        {
          ...data.user
        },
        {
          headers: {
            token: data.accessToken
          }
        }
      );

      if (res.status !== 200) {
        return null;
      } else {
        return res;
      }
    } catch (err) {
      console.error("Login SSO Error Occurred", err);
      return null;
    }
  },

  _signUp: async (email, password, name) => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_ILML_REST_URL}/users`,
        {
          email: email,
          password: password,
          fullName: name
        }
      );

      if (res.status !== 200) {
        return null;
      } else {
        return res;
      }
    } catch (err) {
      console.error("Login Error Occurred", err);
      return null;
    }
  },

  _getSubcategories: async (category) => {
    console.log("_getSubcategories");
    const subCategories = await Axios.get(`${url}item/${category}`);
    if (subCategories.data.length === 0) {
      return null;
    } else {
      let subSection = subCategories.data.slice(1);
      return subSection;
    }
  }
};

export const SellerService = {
  _getSellerProducts: async () => {
    console.log("_getSellerProducts");
    const products = await Axios.get(`${url}sellersproducts`, {
      headers: {
        token: getAndDecodeToken()
      }
    });
    return products.data;
  }
};
