import decode from "jwt-decode";
import { LoginService } from "../services/api/general";

export const MINUTE_MS = 60000;

export async function login(
  email,
  password,
  onSuccess,
  onError,
  authenticateUser
) {
  const res = await LoginService._login(email, password);
  if (!res) {
    console.error(
      "Login Failed. Could require if recently registered to propagate event."
    );
    onError("Issue logging in. Please try Again in a moment.", "info");
    return;
  }

  const data = res.data;
  if (!validateTokenKeys(data?.token)) {
    console.error("Token Validation Failed");
    onError("Token Validation Failed", "error");
    return;
  }

  if (data?.token && authenticateUser(data?.token)) {
    onSuccess();
  } else {
    console.error("Authentication Failed");
    onError("Authentication Failed", "error");
  }
}

// TODO Refactor Auth
export function getAndDecodeToken() {
  //console.log("TOKEN in Storage6:");
  //console.log(localStorage.getItem("TOKEN"));
  const token = localStorage.getItem("TOKEN");
  const decodedToken = token ? decode(token) : "";
  const headerToken = decodedToken?.data["access-token"];
  return headerToken;
}

/**
 * JWT check.
 * @returns True if client has jwt token and token is not expired.
 */
export function isLoggedInClientSide() {
  //console.log("Checking TOKEN in isLoggedInClientSide:");
  //console.log(localStorage.getItem("TOKEN"));
  const token = localStorage.getItem("TOKEN");

  if (!token) return false;

  return isValidToken(token);
}

function isValidToken(token) {
  try {
    const decodedToken = decode(token);
    const currentDate = new Date();
    return decodedToken.exp * 1000 > currentDate.getTime();
  } catch (error) {
    return false;
  }
}
/**
 * JWT token validation.
 * @returns True if all jwt token keys exist.
 */
export function validateTokenKeys(token) {
  if (!token) return false;
  const JWT = decode(token);
  const expectedKeys = [
    "access-token",
    "id",
    "email",
    "fullName",
    "rootItemId"
  ];
  const keyCheck = expectedKeys.every((key) => {
    return JWT.data?.hasOwnProperty(key);
  });

  return keyCheck;
}
