import { Box, Button, Typography } from "@mui/material";
import { darken } from "@mui/material/styles";
import React from "react";
import MenuDrawerItem from "./MenuDrawerItem";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useUserContext } from "../../hooks/userHooks";

export default function MenuDrawerContent(props) {
  const navigate = useNavigate();
  const { user, isLoggedIn, logout } = useUserContext();

  function handleLogout() {
    logout();
    navigate(0);
  }

  function handleLogin() {
    navigate("/signin");
  }

  return (
    <Box
      id="MenuSlideoutBox"
      sx={{
        backgroundColor: "secondary.main",
        height: "100%",
        maxWidth: "70vw",
        minWidth: "15vw",
        width: "auto",
        color: "lightText.main"
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
          backgroundColor: (theme) => darken(theme.palette.secondary.main, 0.5),
          padding: "0.5rem 0 0.5rem 1rem",
          marginBottom: "0.3rem"
        }}>
        <Box
          sx={{
            display: "flex",
            width: "100%"
          }}>
          <Typography
            sx={{
              width: "100%"
            }}
            align="left"
            variant="h6">
            Menu.
          </Typography>
          <Button
            sx={{
              display: "flex",
              height: "2rem"
            }}
            onClick={isLoggedIn ? handleLogout : handleLogin}>
            {isLoggedIn ? (
              <Tooltip title="Logout">
                <LogoutIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Login">
                <LoginIcon />
              </Tooltip>
            )}
          </Button>
        </Box>
        {isLoggedIn ? (
          <Box>
            <Typography
              sx={{
                fontSize: "0.9rem",
                margin: 0
              }}
              align="left"
              paragraph={true}>
              Hello, {user.fullName}
            </Typography>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
      <Box
        sx={{
          color: "lightText.main",
          overflow: "hidden"
        }}>
        {isLoggedIn && (
          <React.Fragment>
            <MenuDrawerItem name="Order History" url="account" />
            <Divider />
            <MenuDrawerItem name="Your Wishlist" url="wishlist" />
            <Divider />
            <MenuDrawerItem name="List A Product" url="createproduct" />
            <Divider />
            <MenuDrawerItem name="Manage Your Products" url="sellerproducts" />
            <Divider />
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}
