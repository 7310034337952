import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import { CategoriesService } from "../../services/api/general";
import { ProductsService } from "../../services/api/general";
import MediaFileView from "./MediaFileView";
import { useSnackbar } from "notistack";
import ReactLoading from "react-loading";
import { checkFile } from "../../validations/files";
import {
  MAX_PRODUCT_DESCRIPTION_LENGHT,
  MAX_PRODUCT_TITLE_LENGHT
} from "../../constants";

export default function ProductForm(props) {
  const [category, setCategory] = useState({ name: "", id: "" });
  const [subcategory, setSubcategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [categoryTitles, setCategoryTitles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [done, setDone] = useState(true);
  useEffect(() => {
    let categoryArray = [];
    CategoriesService._getCategories()
      .then((result) => {
        for (let i = 0; i < result.all.length; i++) {
          categoryArray.push(result.all[i].title);
        }
        setCategoryTitles(categoryArray);
        setCategories(result.all);
      })
      .catch((err) => {
        console.error("Error getting categories", err);
      });
  }, []);
  const handleCategoryChange = (event) => {
    setDone(false);
    let subcategoryArray = [];
    let categoryID = null;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].title === event.target.value) {
        categoryID = categories[i].id;
        setCategory({ name: event.target.value, id: categoryID });
      }
    }

    CategoriesService._getSubcategories(categoryID)
      .then((subresult) => {
        for (let i = 0; i < subresult.length; i++) {
          subcategoryArray.push(subresult[i]);
        }
        setSubcategories(subcategoryArray);
        setDone(true);
      })
      .catch((err) => {
        console.error("Error getting subcategories", err);
        setDone(true);
      });
  };
  const handleSubcategoryChange = (event) => {
    setSubcategory(event.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const validationResult = checkFile(file, props.productInformation.media);
    if (validationResult.error) {
      enqueueSnackbar(validationResult.message, {
        variant: validationResult.variant
      });
      return;
    }

    setDone(false);
    ProductsService._uploadFile(file)
      .then((data) => {
        const isVideoFile = !!data.mimetype.match(/^video\/.*/);
        props.setProductInformation({
          ...props.productInformation,
          media: props.productInformation.media.push(data)
        });
        props.setProductInformation({
          ...props.productInformation,
          isVideoFile: isVideoFile
        });
        setDone(true);
      })
      .catch((err) => {
        setDone(true);
        throw new Error("Failed to Upload File", err);
      });
  };

  const handleMediaCallback = (data) => {
    if (data.length) {
      props.setProductInformation({
        ...props.productInformation,
        media: data
      });
    }
  };

  return (
    <React.Fragment>
      {!done ? (
        <ReactLoading
          type={"spin"}
          color={"#FF9900"}
          height={100}
          width={100}
          className={"loader-overlay"}
        />
      ) : (
        <div></div>
      )}
      <form>
        <Typography variant="h5" gutterBottom>
          Product Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required={true}
              id="name"
              name="name"
              label="Product Name"
              inputProps={{ maxLength: MAX_PRODUCT_TITLE_LENGHT }}
              fullWidth
              variant="outlined"
              value={props.productInformation.name}
              onChange={(e) => {
                props.setProductInformation({
                  ...props.productInformation,
                  name: e.target.value
                });
              }}
              onBlur={(e) => {
                props.setProductInformation({
                  ...props.productInformation,
                  name: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={true}
              id="price"
              name="price"
              label="Price"
              // type="number"
              startadornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              fullWidth
              variant="outlined"
              value={props.productInformation.price}
              onChange={(e) => {
                if (isNaN(e.target.value) || e.target.value < 0) return;
                props.setProductInformation({
                  ...props.productInformation,
                  price: parseFloat(e.target.value).toFixed(2)
                });
              }}
              onBlur={(e) => {
                if (isNaN(e.target.value)) return;
                props.setProductInformation({
                  ...props.productInformation,
                  price: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="description"
              name="description"
              label="Description"
              fullWidth
              multiline
              rows={4}
              inputProps={{
                maxLength: MAX_PRODUCT_DESCRIPTION_LENGHT,
                overflow: "auto",
                resize: "vertical"
              }}
              variant="outlined"
              value={props.productInformation.description}
              onChange={(e) => {
                props.setProductInformation({
                  ...props.productInformation,
                  description: e.target.value
                });
              }}
              onBlur={(e) => {
                props.setProductInformation({
                  ...props.productInformation,
                  description: e.target.value
                });
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <TextField
              required
              id="brand"
              name="brand"
              label="Brand"
              fullWidth
              variant="outlined"
              onBlur={(e) => {
                props.setProductInformation({
                  ...props.productInformation,
                  brand: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="color"
              name="color"
              label="Color"
              fullWidth
              variant="outlined"
              onBlur={(e) => {
                props.setProductInformation({
                  ...props.productInformation,
                  color: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="material"
              name="material"
              label="Material"
              fullWidth
              variant="outlined"
              onBlur={(e) => {
                props.setProductInformation({
                  ...props.productInformation,
                  material: e.target.value,
                });
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="outlined-select-category"
              select
              label="Category"
              value={category.name}
              onChange={handleCategoryChange}
              fullWidth
              onBlur={(e) => {
                props.setProductInformation({
                  ...props.productInformation,
                  category: e.target.value
                });
              }}>
              {Object.values(categoryTitles)?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="outlined-select-category"
              select
              label="Subcategory"
              value={subcategory}
              onChange={handleSubcategoryChange}
              fullWidth
              onBlur={(e) => {
                let subcatId = "";
                for (let i = 0; i < subcategories.length; i++) {
                  if (subcategories[i].title === e.target.value) {
                    subcatId = subcategories[i].id;
                  }
                }
                props.setProductInformation({
                  ...props.productInformation,
                  subcategory: e.target.value,
                  subCategoryId: subcatId
                });
              }}>
              {Object.values(subcategories)?.map((option) => (
                <MenuItem key={option.id} value={option.title}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label" color="primary">
              Upload Image or Video
              <input
                type="file"
                multiple
                accept="image/*, video/*"
                hidden
                onChange={(e) => handleFileChange(e)}
              />
            </Button>
            {props.productInformation.media.length > 0 && (
              <MediaFileView
                parentCallback={handleMediaCallback}
                isVideoFile={props.productInformation.isVideoFile}
                mediaInfo={props.productInformation.media}></MediaFileView>
            )}
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
