import DeleteIcon from "@mui/icons-material/Delete";
import { Card, IconButton, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { CartService } from "../services/api/general";
import { useWindowSize } from "../hooks/uiHooks";
import { MAX_MOBILE_WIDTH } from "../constants/";
import {
  CartItemContainer,
  ProdDetail,
  ProdPrice,
  Thumbnail
} from "./styles/Cart.styles";

function deleteCartItem(cart, productId) {
  return CartService._removeFromCart(productId)
    .then(() => {
      cart = cart.filter((item) => {
        return item.product.id !== productId;
      });
      return cart;
    })
    .catch((err) => {
      throw new Error("Failed to delete cart item", err);
    });
}

function validateQuantity(val) {
  return val >= 0 && val <= 99;
}

/**
 * @todo make dynamic
 */
export default function CartItem(props) {
  const [cart, updateCart] = [props.cart, props.updateCart];
  const [, setInstantPurchase] = [props.setInstantPurchase];
  const setTotal = props.setTotal;
  const setLoading = props.setLoading;
  let onOrderPage = props.onOrderPage;
  const { enqueueSnackbar } = useSnackbar();
  const { windowWidth } = useWindowSize();
  const dark = props.dark ?? true;

  // Only works for newQuantity >= 1 (doesn't work for zero)
  function changeCartItemQuantity(cart, productId, newQuantity) {
    const cartItem = cart.find((item) => item.product.id === productId) || null;

    if (!cartItem) {
      enqueueSnackbar("No Item in the cart", { variant: "warning" });
      return;
    }

    setLoading(true);
    try {
      setLoading(false);
      updateCart(cartItem.product, newQuantity);
      setTotal(props.calculateSubTotal(cart));
      enqueueSnackbar("Update Successful", { variant: "success" });
    } catch (err) {
      setLoading(false);
      console.error("Error updating cart", err);
      enqueueSnackbar("Update Failed", { variant: "error" });
    }
  }

  const handleDelete = () => {
    setLoading(true);
    if (props.singleProductPurchase === undefined) {
      const cartItem =
        cart.find((item) => item.product.id === props.data.id) || null;

      if (!cartItem) {
        enqueueSnackbar("No Item in the cart", { variant: "warning" });
        setLoading(false);
        return;
      }

      try {
        setLoading(false);
        updateCart(cartItem.product, 0);
        setTotal(props.calculateSubTotal(cart));
        enqueueSnackbar("Delete Successful", { variant: "success" });
      } catch (err) {
        console.error("Cart item deletion error", err);
        enqueueSnackbar("Delete Failed", { variant: "error" });
      }
    } else {
      setLoading(false);
      setInstantPurchase([]);
    }
  };

  return (
    <Card
      id="card"
      variant="outlined"
      sx={{
        display: "flex",
        gap: "2rem",
        paddingTop: "0.5rem",
        background: "none",
        border: "none",
        paddingBottom: "10px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: 0,
        height: windowWidth > MAX_MOBILE_WIDTH ? "8rem" : "14rem"
      }}>
      <CartItemContainer
        sx={{
          display: "flex",
          flexDirection: windowWidth > MAX_MOBILE_WIDTH ? "row" : "column",
          width: "100%"
        }}>
        <CartItemContainer
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%"
          }}>
          <CartItemContainer
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              height: "inherit",
              maxWidth: windowWidth > MAX_MOBILE_WIDTH ? "10rem" : "5rem",
              minWidth: windowWidth > MAX_MOBILE_WIDTH ? "10rem" : "5rem"
            }}>
            <Thumbnail src={props.data.picture} alt="Cart Item"></Thumbnail>
          </CartItemContainer>
          <CartItemContainer
            sx={{
              flexGrow: 1,
              maxWidth: "80vw",
              overflow: "hidden",
              color: dark ? "lightText.main" : "darkText.main"
            }}>
            <Typography
              sx={{
                display: "flex",
                flexWrap: "none",
                fontSize: windowWidth > MAX_MOBILE_WIDTH ? "1.5rem" : "1.2rem",
                overflow: "hidden",
                margin: 0,
                fontWeight: windowWidth > MAX_MOBILE_WIDTH ? "normal" : "bold"
              }}>
              {props.data.name}
            </Typography>
            <ProdPrice>
              <strong>${props.data.price}</strong>
            </ProdPrice>
            <ProdDetail>
              Seller: <strong>{props.data.seller}</strong>
            </ProdDetail>
          </CartItemContainer>
        </CartItemContainer>
        <CartItemContainer
          sx={{
            flexGrow: 1,
            maxWidth: "6rem",
            minWidth: "6rem",
            width: "100%"
          }}>
          {onOrderPage ? null : (
            <>
              <TextField
                label="Count"
                type="number"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  min: 1,
                  max: 99
                }}
                defaultValue={props.quantity ?? 1}
                focused
                onBlur={(e) => {
                  /**@todo move to standalone function */
                  if (!validateQuantity(e.target.value)) {
                    let cartItem = cart.find((item) => {
                      return item.id === props.data.id;
                    });
                    e.target.value = cartItem.quantity ?? 1;
                  } else {
                    // removing if 0
                    if (e.target.value == 0) {
                      /**@todo look into alternative to rerender parent prior to promise resolve */
                      handleDelete();
                    } else {
                      /**@todo look into alternative to rerender parent prior to promise resolve */
                      changeCartItemQuantity(
                        cart,
                        props.data.id,
                        e.target.value
                      );
                    }
                  }
                }}
                sx={{
                  minWidth: ["70%", "100%", "100%", "80%"],
                  maxWidth: ["80%", "100%", "100%", "80%"],
                  height: "auto",
                  position:
                    windowWidth > MAX_MOBILE_WIDTH ? "absolute" : "relative",
                  bottom: windowWidth > MAX_MOBILE_WIDTH ? 10 : 0,
                  right: windowWidth > MAX_MOBILE_WIDTH ? 10 : 0,
                  input: {
                    color: dark ? "lightText.main" : "darkText.main",
                    textAlign: "center"
                  },
                  "& .MuiInputBase-root.MuiInputBase-colorPrimary": {
                    "& > fieldset": {
                      borderColor: dark ? "lightText.main" : "darkText.main"
                    }
                  },
                  "& .MuiInputLabel-formControl": {
                    color: dark ? "lightText.main" : "black"
                  }
                }}
              />
              <IconButton
                onClick={() => {
                  /**@todo look into alternative to rerender parent prior to promise resolve */
                  handleDelete();
                }}
                sx={{
                  color: "danger.main",
                  position: "absolute",
                  top: 10,
                  right: windowWidth > MAX_MOBILE_WIDTH ? 10 : -20
                }}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </CartItemContainer>
      </CartItemContainer>
    </Card>
  );
}
