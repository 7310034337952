import React from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import "../global.css";

function ShippingInfoAddingWayForm(props) {
  return (
    <div className="shipping-form">
      <div style={styles.header}>
        <h1 style={styles.headerTitle}>Add Shipping Information by</h1>
      </div>
      <form>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="bypaysystem"
          value={props.shippingInfoAddingWay}
          name="radio-buttons-group">
          <FormControlLabel
            value="byform"
            control={<Radio color="secondary" />}
            label="adding information in the next form"
            onChange={(e) => {
              props.setShippingInfoAddingWay(e.target.value);
            }}
          />
          <FormControlLabel
            value="bypaysystem"
            control={<Radio color="secondary" />}
            label="selecting the shipping address from the Pay System"
            onChange={(e) => {
              props.setShippingInfoAddingWay(e.target.value);
            }}
          />
        </RadioGroup>
      </form>
    </div>
  );
}
const styles = {
  payButtonDiv: {
    display: "flex",
    padding: "20px",
    width: "65%",
    marginBottom: "20px",
    alignSelf: "flex-end",
    justifyContent: "flex-end",
    marginRight: "-15px"
  },
  payButton: {
    backgroundColor: "#FF9900",
    width: "75%",
    alignSelf: "center"
  },
  header: {
    display: "flex"
  },
  headerTitle: {
    width: "65%"
  }
};

export default ShippingInfoAddingWayForm;
