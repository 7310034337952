import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainContainer from "../components/MainContainer";
import Header from "../components/NavigationBar/Header";
import ProductDetails from "../components/SpecificProduct/productDetails";
import ProductTabs from "../components/SpecificProduct/productTabs";
import SimilarProduct from "../components/SpecificProduct/similarProducts";
import { ProductsService } from "../services/api/general";
import ReactLoading from "react-loading";
import productImage from "../components/Checkout/ProductImage";

export default function ProductPage() {
  const params = useParams();
  const [product, setProduct] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [subCatId, setSubCatId] = useState(undefined);
  const [done, setDone] = useState(undefined);
  useEffect(() => {
    setDone(false);
    setSimilarProducts([]);
    const id = params?.id ?? 0;
    if (id === undefined) return;
    ProductsService._getProduct(id)
      .then((result) => {
        if (!!result[0].description) {
          let fields = JSON.parse(result[0].description);
          let currProduct = {
            name: fields.name,
            price: fields.price,
            description: fields.description,
            media: fields.media,
            id: result[0].id,
            seller: fields.sellerName,
            subCategoryId: fields.subCategoryId,
            brand: fields.brand,
            material: fields.material,
            color: fields.color
          };
          setSubCatId(currProduct.subCategoryId);
          setProduct(currProduct);
          setDone(true);
        }
      })
      .catch((err) => {
        console.error("Error getting products", err);
      });
  }, [params?.id]);

  useEffect(() => {
    if (subCatId) {
      ProductsService._getProduct(subCatId).then((result) => {
        for (var i = 1; i < result.length; i++) {
          if (!!result[i].description) {
            let fields = JSON.parse(result[i].description);
            let currProduct = {
              name: fields.name,
              price: fields.price,
              description: fields.description,
              picture: productImage(fields),
              id: result[i].id
            };
            setSimilarProducts((prevState) => [currProduct, ...prevState]);
          }
        }
      });
    }
  }, [subCatId, product]);

  return (
    <div>
      <Header />
      <MainContainer>
        {!done ? (
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={100}
            width={100}
            className={"loader"}
          />
        ) : (
          <>
            <ProductDetails product={product} />
            <ProductTabs product={product} />
            <SimilarProduct
              product={similarProducts.filter(
                (item) => item.name !== product.name
              )}
            />
          </>
        )}
      </MainContainer>
    </div>
  );
}
