import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductForm from "../components/SellerProduct/ProductForm";
import Review from "../components/SellerProduct/Review";
import Header from "../components/NavigationBar/Header";
import { ProductsService } from "../services/api/general";
import { useSnackbar } from "notistack";
import ReactLoading from "react-loading";
import {
  MAX_PRODUCT_DESCRIPTION_LENGHT,
  MAX_PRODUCT_TITLE_LENGHT
} from "../constants";
import { useUserContext } from "../hooks/userHooks";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        Basic Needs.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF9900",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#3E3E3E",
      contrastText: "#FFFFFF"
    }
  }
});

export default function Seller() {
  const { enqueueSnackbar } = useSnackbar();
  const [done, setDone] = useState(true);
  const { user } = useUserContext();
  const [productInformation, setProductInformation] = useState({
    name: "",
    price: "",
    description: "",
    brand: "",
    color: "",
    material: "",
    category: "",
    subcategory: "",
    image: "",
    subCategoryId: "",
    media: []
  }); // const [skipped, setSkipped] = React.useState(new Set());

  let navigate = useNavigate();
  const routeHome = () => {
    let path = "/";
    navigate(path);
  };
  const routeSeller = () => {
    window.location.reload(false);
  };
  const steps = ["Product Details", "Review Product Details"];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ProductForm
            productInformation={productInformation}
            setProductInformation={setProductInformation}
          />
        );
      case 1:
        return (
          <Review
            productInfoState={productInformation}
            setProductInfoState={setProductInformation}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (productInformation.name === "") {
      enqueueSnackbar("Missing Product Title", { variant: "error" });
    } else if (productInformation.name.length > MAX_PRODUCT_TITLE_LENGHT) {
      enqueueSnackbar(
        `Product Name Longer Than ${MAX_PRODUCT_TITLE_LENGHT} Characters`,
        {
          variant: "error"
        }
      );
    } else if (productInformation.price === "") {
      enqueueSnackbar("Missing Product Price", { variant: "error" });
    } else if (productInformation.description === "") {
      enqueueSnackbar("Missing Product Description", { variant: "error" });
    } else if (
      productInformation.description.length > MAX_PRODUCT_DESCRIPTION_LENGHT
    ) {
      enqueueSnackbar(
        `Product Description Longer Than ${MAX_PRODUCT_DESCRIPTION_LENGHT} Characters`,
        { variant: "error" }
      );
    } else if (productInformation.category === "") {
      enqueueSnackbar("Missing Product Category", { variant: "error" });
    } else if (productInformation.subcategory === "") {
      enqueueSnackbar("Missing Product Subcategory", { variant: "error" });
    } else if (!/^[0-9.,]+$/.test(productInformation.price)) {
      enqueueSnackbar("Price Must Be A Number", { variant: "error" });
    } else if (!productInformation.media?.length) {
      enqueueSnackbar("Please select an image or video for your product", {
        variant: "error"
      });
    } else {
      let fixedPrice = parseFloat(productInformation.price).toFixed(2);
      setProductInformation({ ...productInformation, price: fixedPrice });
      setActiveStep(activeStep + 1);
    }
  };

  const handleSubmit = (product) => {
    setDone(false);
    ProductsService._createProduct(product)
      .then(() => {
        setDone(true);
        setActiveStep(activeStep + 1);
      })
      .catch((err) => {
        setDone(true);
        console.log("Error saving product", err);
        enqueueSnackbar("Product has not been saved", {
          variant: "error"
        });
      });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Header />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {!done ? (
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={100}
            width={100}
            className={"loader"}
          />
        ) : (
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                fontFamily="'Inter', sans-serif;"
                fontWeight="700"
                color="var(--tertiary-colour)">
                Basic Needs.
              </Typography>
              <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Thank you for your post {user.fullName}!
                    </Typography>
                    <Typography variant="subtitle1">
                      Have another product? Start another post!
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Button
                        variant="contained"
                        onClick={routeHome}
                        sx={{ mt: 3, ml: 1 }}>
                        Home
                      </Button>

                      <Button
                        variant="contained"
                        onClick={routeSeller}
                        sx={{ mt: 3, ml: 1 }}>
                        Start Another
                      </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getStepContent(activeStep)}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                          Back
                        </Button>
                      )}

                      {activeStep === steps.length - 1 ? (
                        <Button
                          variant="contained"
                          onClick={() => handleSubmit(productInformation)}
                          sx={{ mt: 3, ml: 1 }}>
                          Submit
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 3, ml: 1 }}>
                          Next
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Paper>
            <Copyright />
          </Container>
        )}
      </ThemeProvider>
    </>
  );
}
