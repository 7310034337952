import eventEmitter from "./eventEmitter";
import { ENVIRONMENT, CURRENCY, STRIPE_PUBLISHABLE_KEY } from "./config";

export function paymentHandler(purchaseItems, shippingAddressRequired) {
  createPaymentRequest(purchaseItems, shippingAddressRequired)
    .show()
    .then(async function (response) {
      await response.complete("success");
      eventEmitter.emit("handlePaymentResponse", {
        purchaseItems,
        shippingAddressRequired,
        response
      });
    })
    .catch(function (err) {
      console.log("PaymentRequest Error", err);
    });
}

function getDetails(items) {
  let displayItems = [];
  const total = {
    label: "Total due",
    amount: { currency: CURRENCY, value: 0 }
  };
  let subTotal = 0;
  let totalCost = 0;
  items.forEach((item) => {
    totalCost = item.product.price * item.qty;
    displayItems.push({
      label: item.product.name,
      amount: { currency: CURRENCY, value: totalCost }
    });
    subTotal += totalCost;
  });
  total.amount.value = subTotal;

  return {
    displayItems: displayItems,
    total: total
  };
}

function createPaymentRequest(purchaseItems, shippingAddressRequired) {
  if (!window.PaymentRequest) {
    console.log("PaymentRequest is not available");
    return;
  }

  const methodData = [
    {
      supportedMethods: "https://google.com/pay",
      data: googlePaymentsConfiguration
    }
    // { supportedMethods: "https://apple.com/apple-pay", data: "applePaymentsConfiguration"}
    // { supportedMethods: "basic-card" }
  ];

  const details = getDetails(purchaseItems);
  const options = {
    requestPayerEmail: true,
    requestPayerName: true,
    shippingAddressRequired: shippingAddressRequired
  };

  let request = new PaymentRequest(methodData, details, options);

  /*
  *  Commented for Apple Pay ---------
  *
  request.onmerchantvalidation = async (event) => {
    
    let merchantSessionPromise = null;
    try {
      merchantSessionPromise = await client.query({
        query: GET_MERCHANT_SESSION,
        variables: { url: event.validationURL }
      });
        console.log('merchantSessionPromise', merchantSessionPromise);
    } catch (error) {
        notification.error({
            message: APP_LABEL,
            description: error.message || 'Sorry! Something went wrong. Please try again!'
        });
        console.log('Error', error);
    }

    event.complete(merchantSessionPromise);
  };

  request.onpaymentmethodchange = event => {
    if (event.methodDetails.type !== undefined) {
        const paymentDetailsUpdate = {
            'total': paymentDetails.total
        };
        event.updateWith(paymentDetailsUpdate);
    } else if (event.methodDetails.couponCode !== undefined) {
        const total = calculateTotal(event.methodDetails.couponCode);
        const displayItems = calculateDisplayItem(event.methodDetails.couponCode);
        const shippingOptions = calculateShippingOptions(event.methodDetails.couponCode);
        const error = calculateError(event.methodDetails.couponCode);

        event.updateWith({
            total: total,
            displayItems: displayItems,
            shippingOptions: shippingOptions,
            modifiers: [
                {
                    data: {
                        additionalShippingMethods: shippingOptions,
                    },
                },
            ],
            error: error,
        });
    }
  };

  request.onshippingoptionchange = event => {
    const paymentDetailsUpdate = {
        'total': paymentDetails.total
    };
    event.updateWith(paymentDetailsUpdate);
  };

  request.onshippingaddresschange = event => {
    const paymentDetailsUpdate = {};
    event.updateWith(paymentDetailsUpdate);
  };
  *
  * ------ Commented for Apple Pay
  */

  return request;
}

const googlePaymentsConfiguration = {
  environment: ENVIRONMENT,
  apiVersion: 2,
  apiVersionMinor: 0,
  merchantInfo: {
    merchantId: "BCR2DN4TQCVZ7OI7",
    merchantName: "BasicNeeds Pay"
  },
  allowedPaymentMethods: [
    {
      type: "CARD",
      parameters: {
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowedCardNetworks: [
          "AMEX",
          "DISCOVER",
          "INTERAC",
          "JCB",
          "MASTERCARD",
          "VISA"
        ]
      },
      tokenizationSpecification: {
        type: "PAYMENT_GATEWAY",
        parameters: {
          gateway: "stripe",
          "stripe:version": "v3",
          "stripe:publishableKey": STRIPE_PUBLISHABLE_KEY
        }
      }
    }
  ]
};

/*
  *  Commented for Apple Pay ---------
  *
const applePaymentsConfiguration = {
  version: 4,
  merchantIdentifier: "merchant.io.ilivemylife.app",
  merchantCapabilities: ["supports3DS"],
  supportedNetworks: ["amex", "discover", "masterCard", "visa"],
  countryCode: "CA"
};
*
* ------ Commented for Apple Pay
*/
