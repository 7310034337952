import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

const columns = [
  {
    id: "products",
    label: "Product",
    align: "center",
    width: "100px"
  },
  // { id: "orderNumber", label: "Order Number", align: "center" },
  // { id: "date", label: "Date", align: "center" },
  {
    id: "name",
    label: "Product Name",
    align: "center"
  },
  {
    id: "sellerName",
    label: "Seller Name",
    align: "center"
  },
  {
    id: "subtotal",
    label: "Price",
    align: "center",
    format: (value) => value.toLocaleString("en-US")
  },
  {
    id: "quantity",
    label: "Quantity",
    align: "center"
  },
  {
    id: "fulfilled",
    label: "Status",
    align: "center"
  }
  // {
  //   id: "shippingInfo",
  //   label: "Shipping Information",
  //   minWidth: 100,
  //   align: "center",
  // },
];

export default function BasicModal({ row }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Button onClick={handleOpen} style={{ backgroundColor: "#3E3E3E" }}>
        View Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div
            className="order-details-header"
            style={styles.orderDetailsHeader}>
            <Typography id="modal-modal-title" variant="h4" component="h4">
              Order Details
              <Typography align={"left"} marginTop={"20px"}>
                Order Number: {row.confirmationNumber}
              </Typography>
              <Typography align={"left"} justifyContent={"flex-start"}>
                Order Date: {row.date}
              </Typography>
              <Typography align={"left"}>
                Order Subtotal: ${row.subtotal}
              </Typography>
            </Typography>
            <div style={styles.shippingInformation}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                style={styles.shippingInformation}>
                Shipping Information
                <Typography>
                  {row.shippingInformation.address}
                  {"\n"}
                  {row.shippingInformation.city}
                  {", "}
                  {row.shippingInformation.province}
                  {", "}
                  {row.shippingInformation.country}
                  {"\n"}
                  {row.shippingInformation.postalcode}
                </Typography>
              </Typography>
            </div>
          </div>

          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ overflowY: "scrollable" }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {row.products.map((product) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}>
                      <TableCell
                        align={columns[0].align}
                        height={columns[0].height}
                        width={"100px"}>
                        <img
                          src={product.image}
                          alt={product.name}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </TableCell>
                      {/* <TableCell align={columns[0].align}>
                        {row.confirmationNumber}
                      </TableCell> */}
                      {/* <TableCell align={columns[1].align}>{row.date}</TableCell> */}
                      <TableCell
                        align={columns[2].align}
                        style={{ minWidth: "100px" }}>
                        {product.name}
                      </TableCell>
                      <TableCell
                        align={columns[2].align}
                        style={{ minWidth: "100px" }}>
                        {product.sellerName}
                      </TableCell>
                      <TableCell
                        align={columns[3].align}
                        style={{ minWidth: "100px" }}>
                        ${product.price}
                      </TableCell>
                      <TableCell
                        align={columns[4].align}
                        style={{ minWidth: "100px" }}>
                        {product.quantity}
                      </TableCell>
                      <TableCell
                        align={columns[5].align}
                        style={{ minWidth: "100px" }}>
                        {product.status ? "Fulfilled" : "Pending"}
                      </TableCell>
                      {/* <TableCell align={columns[5].align}>
                        {row.shippingInformation.address}
                        {"\n"}
                        {row.shippingInformation.city}
                        {", "}
                        {row.shippingInformation.province}
                        {", "}
                        {row.shippingInformation.country}
                        {"\n"}
                        {row.shippingInformation.postalcode}
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
const styles = {
  orderDetailsHeader: {
    display: "flex",
    width: "100%"
  },
  shippingInformation: {
    justifyContent: "flex-end",
    flex: "1",
    float: "right",
    width: "30%",
    padding: "10px",
    marginTop: "15px"
  }
};
