import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";

export default function Product({ name, price, description, picture, id }) {
  return (
    <Card
      className="productCard"
      key={id}
      sx={{
        maxWidth: 200,
        maxHeight: "20rem",
        borderRadius: 0,
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
      }}>
      <CardActionArea component={Link} to={`/product/${id}`}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: 200,
            height: 200
          }}>
          {picture ? (
            <CardMedia
              component="img"
              image={picture}
              alt={picture}
              sx={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
                pt: 2
              }}
            />
          ) : (
            <IconButton>
              <PersonalVideoIcon></PersonalVideoIcon>
            </IconButton>
          )}
        </Box>

        <CardContent
          sx={{
            padding: 0
          }}>
          <Divider sx={{ marginTop: "0.5rem" }} />
          <Box sx={{ padding: 1, height: "35px", overflow: "hidden" }}>
            <Typography
              variant="h7"
              fontWeight={600}
              component="div"
              sx={{
                textAlign: "center",
                whiteSpace: "normal", // Allows text to wrap to the next line
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box", // Required for line clamping
                WebkitLineClamp: 2, // Specifies the number of lines after which to truncate text
                WebkitBoxOrient: "vertical", // Required for line clamping
                lineHeight: "1.2rem", // Adjust line height to ensure that two lines fit correctly
                maxHeight: "2.4rem", // Max height to accommodate two lines of text
                paddingX: 1 // Horizontally pads the text to ensure it doesn't touch the edges
              }}>
              {name}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "tertiary.main"
            }}>
            <Typography
              variant="h6"
              fontWeight={420}
              component="div"
              sx={{ textAlign: "center" }}>
              {"$" + price ?? "Free"}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
