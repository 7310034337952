import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import MediaFileView from "./MediaFileView";
import ReactMarkdown from "react-markdown";
import { useUserContext } from "../../hooks/userHooks";

export default function Review(props) {
  const { user } = useUserContext();
  let productInfo = props.productInfoState;

  const handleMediaCallback = (data) => {
    if (data.length) {
      props.setProductInfoState({
        ...props.productInfoState,
        media: data
      });
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Product Details Summary
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
        {`Price: $${productInfo.price}`}
      </Typography>
      <List disablePadding>
        <ListItem key="Product1" sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={productInfo.name}
            secondary={<ReactMarkdown>{productInfo.description}</ReactMarkdown>}
          />
        </ListItem>
        <MediaFileView
          parentCallback={handleMediaCallback}
          mediaInfo={productInfo.media}></MediaFileView>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Seller Details
          </Typography>
          <Typography gutterBottom>{user.fullName}</Typography>
        </Grid>
        {/* <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Additional Details
          </Typography>
          <Grid container>
            <React.Fragment>
              <Grid item xs={12}>
                <Typography gutterBottom>Brand: {productInfo.brand}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>Color: {productInfo.color}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Material: {productInfo.material}
                </Typography>
              </Grid>
            </React.Fragment>
          </Grid>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
