import CreditCardIcon from "@mui/icons-material/CreditCard";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Payment from "../components/Checkout/Payment";
import ShippingInfo from "../components/Checkout/ShippingInfo";
import ShippingInfoAddingWay from "../components/Checkout/ShippingInfoAddingWay";
import CartDrawerContent from "../components/Drawers/CartDrawerContent";
import Header from "../components/NavigationBar/Header";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useSnackbar } from "notistack";
import { OrdersService } from "../services/api/general";
import { alpha } from "@mui/material/styles";
import { paymentHandler } from "../services/payments/paymentsHandler";
import eventEmitter from "../services/payments/eventEmitter";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { useUserContext } from "../hooks/userHooks";

const steps = [
  "Confirm Cart",
  "Add Shipping Information by",
  "Shipping Form",
  "Confirm and Pay"
];

function calculateSubTotal(cart) {
  let sum = 0;
  cart?.forEach((item) => {
    sum += item.product.price * item.qty;
  });
  (Math.round(sum * 100) / 100).toFixed(2);

  return sum;
}

export default function HorizontalLinearStepper() {
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { cart, instantPurchase } = useUserContext();
  const [singleProduct] = useState(
    instantPurchase !== undefined && instantPurchase.length !== 0
  );
  const [done, setDone] = useState(true);
  const [shippingInformation, setShippingInformation] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    province: "",
    country: "",
    postalCode: ""
  });
  const [shippingInfoAddingWay, setShippingInfoAddingWay] = React.useState("");
  const navigate = useNavigate();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let errorMsg = "";
    let interval = 1;
    if (activeStep === 2) {
      if (shippingInformation.firstName === "") {
        errorMsg = "Missing First Name";
      } else if (shippingInformation.lastName === "") {
        errorMsg = "Missing Last Name";
      } else if (shippingInformation.email === "") {
        errorMsg = "Missing Email";
      } else if (shippingInformation.address === "") {
        errorMsg = "Missing Address";
      } else if (shippingInformation.city === "") {
        errorMsg = "Missing city";
      } else if (shippingInformation.province === "") {
        errorMsg = "Missing Province";
      } else if (shippingInformation.country === "") {
        errorMsg = "Missing Country";
      } else if (shippingInformation.postalCode === "") {
        errorMsg = "Missing Postal Code";
      }

      if (errorMsg) {
        enqueueSnackbar(errorMsg, { variant: "error" });
        return;
      }
    } else if (activeStep === 1) {
      if (!shippingInfoAddingWay) {
        enqueueSnackbar(
          "The way of adding Shipping Information is not selected ",
          {
            variant: "error"
          }
        );
        return;
      }

      if (shippingInfoAddingWay === "bypaysystem") {
        interval = 2;
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + interval);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep === 3 && shippingInfoAddingWay === "bypaysystem") {
      setActiveStep(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getProductItems = () => {
    return singleProduct ? instantPurchase : cart;
  };

  const handleSubmit = () => {
    setDone(false);
    let purchaseItems = getProductItems();
    eventEmitter.once("handlePaymentResponse", handlePaymentResponse);
    const shippingAddressRequired =
      shippingInfoAddingWay === "bypaysystem" ?? false;
    paymentHandler(purchaseItems, shippingAddressRequired);
  };

  let cartSubtotal = 0;
  if (singleProduct) {
    cartSubtotal = calculateSubTotal(instantPurchase);
  } else {
    cartSubtotal = calculateSubTotal(cart);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CartDrawerContent
            onCheckoutPage={true}
            singleProductPurchase={singleProduct}
            dark={false}
            sx={{
              padding: 0
            }}
          />
        );
      case 1:
        return (
          <ShippingInfoAddingWay
            shippingInfoAddingWay={shippingInfoAddingWay}
            setShippingInfoAddingWay={setShippingInfoAddingWay}
          />
        );
      case 2:
        return (
          <ShippingInfo
            shippingInformation={shippingInformation}
            setShippingInformation={setShippingInformation}
          />
        );
      case 3:
        return (
          <Payment
            subtotal={cartSubtotal}
            singleProductPurchase={singleProduct}
            shippingInfo={shippingInformation}
            shippingInfoAddingWay={shippingInfoAddingWay}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  function onClose() {
    const prevLink = localStorage.getItem("LOCATION_PREV_PATH");
    const returnUrl = prevLink ? prevLink : "/";

    localStorage.setItem("LOCATION_PREV_PATH", "");
    navigate(returnUrl);
  }

  function handlePaymentResponse(data) {
    console.log("handlePaymentResponse");
    if (data.response.details.errorCode > 0) {
      console.log(
        "Error:",
        data.response.details.statusCode,
        data.response.details.statusMessage
      );
      enqueueSnackbar("Payment is not done", { variant: "error" });
      navigate("/");
      return;
    }

    const shippingAddress =
      data.shippingAddressRequired && data.response.shippingAddress
        ? {
            firstName: data.response.shippingAddress.name.split(" ")[0],
            lastName: data.response.shippingAddress.name.split(" ")[1],
            email: data.response.payerEmail,
            address: "".concat(
              data.response.shippingAddress.address1,
              " ",
              data.response.shippingAddress.address2,
              " ",
              data.response.shippingAddress.address3
            ),
            city: data.response.shippingAddress.locality,
            province: data.response.shippingAddress.administrativeArea,
            country: data.response.shippingAddress.countryCode,
            postalCode: data.response.shippingAddress.postalCode
          }
        : shippingInformation;

    OrdersService._createOrders(data.purchaseItems, shippingAddress).then(
      (res) => {
        setDone(true);
        const orderNumber = res.data;
        navigate("/confirmation", {
          state: {
            shippingInfo: shippingAddress,
            cart,
            orderNumber,
            instantPurchase: data.purchaseItems
          }
        });
      }
    );
  }

  function Next() {
    if (!cartSubtotal) {
      return;
    }

    return (
      <Button
        variant="contained"
        onClick={handleNext}
        sx={{
          mt: 3,
          ml: 1,
          backgroundColor: "#FF9900",
          color: "#FFFFFF"
        }}>
        Next
      </Button>
    );
  }

  return (
    <>
      <Header />
      {!done ? (
        <ReactLoading
          type={"spin"}
          color={"#FF9900"}
          height={100}
          width={100}
          className={"loader"}
        />
      ) : (
        <Container
          component="main"
          maxWidth="md"
          sx={{ mb: 4, height: "800px" }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ textAlign: "right", m: 1 }}>
              <Tooltip title="Close">
                <IconButton onClick={onClose}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              fontFamily="'Inter', sans-serif;"
              fontWeight="700"
              color="var(--tertiary-colour)">
              Basic Needs.
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#FF9900" // circle color (COMPLETED)
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                      {
                        color: "grey.500" // Just text label (COMPLETED)
                      },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "#FF9900" // circle color (ACTIVE)
                    },
                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                      {
                        color: "common.white" // Just text label (ACTIVE)
                      },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "black" // circle's number (ACTIVE)
                    }
                  }}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order number is #2001539. We have emailed your order
                    confirmation, and will send you an update when your order
                    has shipped.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box
                    style={{ height: "500px" }}
                    sx={{
                      padding: "0 10px",
                      border: "1px solid",
                      borderColor: (theme) =>
                        alpha(theme.palette.secondary.main, 0.2),
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                    }}>
                    {getStepContent(activeStep)}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%"
                    }}>
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        sx={{
                          mt: 3,
                          ml: 1,
                          color: "#FF9900",
                          "& css-dp69xc-MuiButtonBase-root-MuiButton-root:hover":
                            {
                              backgroundColor: "black"
                            }
                        }}>
                        Back
                      </Button>
                    )}

                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        endIcon={<CreditCardIcon />}
                        sx={{
                          mt: 3,
                          ml: 1,
                          backgroundColor: "#FF9900",
                          color: "#FFFFFF"
                        }}>
                        Place Order
                      </Button>
                    ) : (
                      Next()
                    )}
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Container>
      )}
    </>
  );
}
