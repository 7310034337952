import Header from "../components/NavigationBar/Header";
import MainContainer from "../components/MainContainer";
import React, { useEffect, useState } from "react";
import "./Home.css";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { ProductsService } from "../services/api/general";
import Product from "../components/Product";
import productImage from "../components/Checkout/ProductImage";

function Home() {
  const [productsTodaysDeals, setProductsTodaysDeals] = useState([]);
  const [productsBestSellingItems, setProductsBestSellingItems] = useState([]);

  const getProduct = (item) => {
    return (
      <Product
        key={item.id}
        id={item.id}
        name={item.name}
        description={item.description}
        price={item.price}
        picture={item.picture}
      />
    );
  };

  const productsTodaysDealsView = productsTodaysDeals.map((item) => {
    return getProduct(item);
  });

  const productsBestSellingItemsView = productsBestSellingItems.map((item) => {
    return getProduct(item);
  });

  const responsive = {
    0: { items: 1, itemsFit: "contain" },
    490: { items: 2, itemsFit: "contain" },
    700: { items: 3, itemsFit: "contain" },
    1100: { items: 4, itemsFit: "contain" },
    1400: { items: 5, itemsFit: "contain" }
  };

  const getSpecificCategoryProducts = (name) => {
    console.log("_getSpecificCategoryProducts 1");
    ProductsService._getSpecificCategoryProducts(name)
      .then((res) => {
        let formattedProducts = [];
        if (!!res?.data) {
          res.data.forEach((item) => {
            if (!!item.description) {
              let fields = JSON.parse(item.description);
              if (fields.price > 0) {
                let newProduct = {
                  name: fields.name,
                  description: fields.description,
                  price: fields.price,
                  picture: productImage(fields),
                  id: item.id
                };
                formattedProducts.push(newProduct);
              }
            }
          });
        }

        if (name === "todaysdeals") {
          setProductsTodaysDeals(formattedProducts);
        } else if (name === "bestsellingitems") {
          setProductsBestSellingItems(formattedProducts);
        }
      })
      .catch((err) => {
        console.error("Error getting products", err);
      });
  };

  useEffect(() => {
    getSpecificCategoryProducts("todaysdeals");
    getSpecificCategoryProducts("bestsellingitems");
  }, []);

  const CarouselTodaysDeals = () => (
    <AliceCarousel
      mouseTracking
      items={productsTodaysDealsView}
      responsive={responsive}
      controlsStrategy="responsive"
      disableButtonsControls="false"
      keyboardNavigation="true"
    />
  );

  const CarouselBestSellingItems = () => (
    <AliceCarousel
      mouseTracking
      items={productsBestSellingItemsView}
      responsive={responsive}
      controlsStrategy="responsive"
      disableButtonsControls="false"
      keyboardNavigation="true"
    />
  );

  return (
    <div>
      <Header />
      <MainContainer>
        <div className="bennerBox">
          <img
            src="../poster/BNbenner.gif"
            alt="Opening benner"
            className="benner"
          />
        </div>
        <div className="iconBox">
          <span className="icon">
            <GppGoodOutlinedIcon />
            Safe payments
          </span>
          <span className="icon">
            <LocalShippingOutlinedIcon />
            Secure logistics
          </span>
          <span className="icon">
            <LockOutlinedIcon />
            Secure privacy
          </span>
          <span className="icon">
            <HandshakeOutlinedIcon />
            Purchase protection
          </span>
        </div>
        <div className="heroBox">
          <img
            src="../poster/heroPoster1.png"
            alt="Opening poster"
            width="6912"
            height="3456"
            className="heroPoster"
          />
          <img
            src="../poster/heroPoster2.png"
            alt="Hero potser"
            width="6912"
            height="3456"
            className="heroPoster"
          />
        </div>
        <div className="midPosterBox">
          <img
            src="../poster/midPoster1.png"
            alt="Poster"
            width="1080"
            height="1080"
            className="midPoster"
          />
          <img
            src="../poster/midPoster2.png"
            alt="Poster"
            width="1080"
            height="1080"
            className="midPoster"
          />
          <img
            src="../poster/midPoster3.png"
            alt="Poster"
            width="1080"
            height="1080"
            className="midPoster"
          />
        </div>
        <div className="cardGrid">
          <a className="cardBox" href="/">
            <img
              src="../poster/poster3.jpg"
              alt="Electronics"
              width="449"
              height="400"
              className="cards"
            />
            <p className="options">Electronics</p>
          </a>
          <a className="cardBox" href="/">
            <img
              src="../poster/poster2.jpg"
              alt="Fashion"
              width="449"
              height="400"
              className="cards"
            />
            <p className="options">Fashion Deals</p>
          </a>
          <a className="cardBox" href="/">
            <img
              src="../poster/poster5.jpg"
              alt="Refresh Your Space"
              width="449"
              height="400"
              className="cards"
            />
            <p className="options">Refresh Your Space</p>
          </a>
          <a className="cardBox" href="/">
            <img
              src="../poster/poster1.jpg"
              alt="Get Ready For Travel"
              width="449"
              height="400"
              className="cards"
            />
            <p className="options">Get Ready For Travel</p>
          </a>
          <a className="cardBox" href="/">
            <img
              src="../poster/poster6.jpg"
              alt="Sporty Your Life"
              width="449"
              height="400"
              className="cards"
            />
            <p className="options">Sporty Your Life</p>
          </a>
          <a className="cardBox" href="/">
            <img
              src="../poster/poster8.jpg"
              alt="New Arrivals In Toys"
              width="449"
              height="400"
              className="cards"
            />
            <p className="options">New Arrivals In Toys</p>
          </a>
          <a className="cardBox" href="/">
            <img
              src="../poster/poster7.jpg"
              alt="Discover Audio"
              width="449"
              height="400"
              className="cards"
            />
            <p className="options">Discover Audio</p>
          </a>
          <a className="cardBox" href="/">
            <img
              src="../poster/poster4.jpg"
              alt="Shop Pet Supplies"
              width="449"
              height="400"
              className="cards"
            />
            <p className="options">Shop Pet Supplies</p>
          </a>
        </div>
        <div className="dealsBox">
          <p>Today's Deals</p>
          <div className="deals">
            {/* waiting - use the data in the database to showcase key products and price */}
            <CarouselTodaysDeals></CarouselTodaysDeals>
          </div>
        </div>

        <div className="dealsBox">
          <p>Bestselling items</p>
          <div className="deals">
            {/* waiting - use the data in the database to showcase popular products and price */}
            <CarouselBestSellingItems></CarouselBestSellingItems>
          </div>
        </div>
      </MainContainer>
    </div>
  );
}

export default Home;
