import { Box, Divider, Typography } from "@mui/material";
import { alpha, darken } from "@mui/material/styles";
import { useContext, Fragment } from "react";
import { CategoryContext } from "../../context/Contexts";
import { CenteredDiv } from "../styles/Cart.styles";
import CategoriesSlideOutItem from "./CategoriesDrawerItem";
import ReactLoading from "react-loading";
/**
 *
 * @param {Array} categories
 */
function generateCategories(
  categories,
  openCategory,
  setOpenCategory,
  setDrawerOpen
) {
  if (categories === undefined) return;

  let elements = [];

  //console.log("categories");
  //console.log(categories);

  categories.forEach((category) => {
    let currCategory = {
      name: category.title,
      id: category.id
    };
    elements.push(
      <Fragment key={category.id}>
        <CategoriesSlideOutItem
          category={currCategory}
          openCategory={openCategory}
          setOpenCategory={setOpenCategory}
          setDrawerOpen={setDrawerOpen}
          sx={{ padding: "0 1rem 0 0rem" }}
        />
        <Divider
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.1)
          }}
        />
      </Fragment>
    );
  });

  return elements.length === 0 ? (
    <CenteredDiv>
      <ReactLoading type={"spin"} color={"#FF9900"} width="15%" />
    </CenteredDiv>
  ) : (
    elements
  );
}
export default function CategoriesSlideOutContent(props) {
  const [categories] = useContext(CategoryContext);
  return (
    <Box
      id="category-slideout-content"
      sx={{
        backgroundColor: "secondary.main",
        height: "100%",
        color: "lightText.main",
        overflow: "hidden",
        minWidth: "15vw",
        maxWidth: "90vw"
      }}>
      <Box
        sx={{
          height: "5%",
          backgroundColor: (theme) => darken(theme.palette.secondary.main, 0.5),
          padding: "0.5rem 0 0.5rem 1rem",
          marginBottom: "0.5rem"
        }}>
        <Typography
          sx={{
            height: "100%",
            paddingLeft: "0.5rem"
          }}
          variant="h5"
          gutterBottom
          component="div">
          All Categories.
        </Typography>
      </Box>
      <Box
        id="category-container"
        sx={{
          height: "92%",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar-thumb": {
            width: 0.5,
            backgroundColor: (theme) => alpha(theme.palette.tertiary.main, 1)
          }
        }}>
        {generateCategories(categories.all, props.setOpen)}
      </Box>
    </Box>
  );
}
