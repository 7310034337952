import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import "../../global.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography style={styles.styledTabs}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function ProductTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "100%" }}
      style={{ marginLeft: "30px", marginRight: "30px" }}>
      <Box>
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered>
          <Tab
            style={styles.styledBoldTabs}
            label="DETAILS"
            {...a11yProps(0)}
          />
          <Tab
            style={styles.styledBoldTabs}
            label="REVIEWS"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        Brand: {props.product?.brand ?? "-"}
        <br />
        Color: {props.product?.color ?? "-"}
        <br />
        Material: {props.product?.material ?? "-"}
        <br />
        Seller: {props.product?.seller ?? "-"}
      </TabPanel>
      <TabPanel value={value} index={1}>
        Review:
      </TabPanel>
      <Divider variant="middle" />
    </Box>
  );
}

const styles = {
  styledTabs: {
    fontFamily: "'Inter', sans-serif"
  },
  styledBoldTabs: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: "700"
  }
};

export default ProductTabs;
