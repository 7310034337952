import MenuIcon from "@mui/icons-material/Menu";
import { alpha } from "@mui/material/styles";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useContext, useState } from "react";
import * as Router from "react-router-dom";
import { CategoryContext } from "../../context/Contexts";
import { useWindowSize } from "../../hooks/uiHooks";
import "../../styles/header.css";
import CartDrawerContent from "../Drawers/CartDrawerContent";
import CategoriesSlideOutContent from "../Drawers/CategoriesDrawerContent";
import PageDrawer from "../Drawers/PageDrawer";
import { CART_PADDING_LEFT, CART_PADDING_RIGHT } from "../../constants";
import { useUserContext } from "../../hooks/userHooks";

function StyledTab(props) {
  return (
    <Tab
      {...props}
      sx={{
        color: "lightText.main",
        textDecoration: "none",
        ":hover": {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1)
        }
      }}
    />
  );
}

/**
 * Handles the amount of category tabs to display depending on container width.
 * @param {*} windowSize
 * @returns
 */
function renderCategoryTabs(categories, windowWidth) {
  const MAX_TABS = 10;
  const MIN_WINDOW_WIDTH = 600;

  if (windowWidth <= MIN_WINDOW_WIDTH) return;

  let tabArray = [];

  for (let i = 0; i < MAX_TABS && i < categories.length; i++) {
    const name = categories[i]?.title;
    const id = categories[i]?.id;

    const tabElement = (
      <StyledTab
        component={Router.Link}
        to={`/category/${name}/${id}`}
        key={`category-tab-${id}`}
        label={name}
      />
    );
    tabArray.push(tabElement);
  }

  return tabArray;
}

export default function NavTabs() {
  const [categories] = useContext(CategoryContext);
  const location = Router.useLocation();
  // const [setValue] = useState(0);
  const [cartOpen, setCartOpen] = useState(false);
  const [viewAllOpen, setViewAllOpen] = useState(false);
  const { isLoggedIn } = useUserContext();
  const disabledCartPages = ["/checkout"];
  const windowSize = useWindowSize();

  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}>
      <div id="nav-links">
        <StyledTab
          textColor="inherit"
          icon={<MenuIcon />}
          iconPosition="start"
          label="View All"
          aria-label="All Category Tab"
          onClick={() => setViewAllOpen(true)}
          sx={{
            textDecoration: "none",
            ":hover": {
              backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1)
            }
          }}
        />
        <div id="optional-nav-links">
          {renderCategoryTabs(categories?.top, windowSize.windowWidth)}
        </div>
        {isLoggedIn ? (
          <StyledTab
            icon={<ShoppingCartOutlinedIcon />}
            iconPosition="start"
            label="Cart"
            disabled={
              disabledCartPages.find((item) => item === location.pathname) !==
              undefined
            }
            onClick={() => setCartOpen(true)}
            aria-label="Cart Tab"
            sx={{ alignSelf: "flex-end" }}
          />
        ) : (
          <div></div>
        )}
      </div>
      <PageDrawer
        direction="right"
        open={
          cartOpen &&
          !disabledCartPages.find((item) => item === location.pathname)
        }
        setOpen={setCartOpen}>
        <Box
          sx={{
            overflow: "hidden",
            backgroundColor: "secondary.main",
            height: "100%",
            padding: `0 ${CART_PADDING_RIGHT}px 0 ${CART_PADDING_LEFT}px`
          }}>
          <CartDrawerContent setOpen={setCartOpen} />
        </Box>
      </PageDrawer>
      <PageDrawer direction="left" open={viewAllOpen} setOpen={setViewAllOpen}>
        <CategoriesSlideOutContent setOpen={setViewAllOpen} />
      </PageDrawer>
    </Box>
  );
}
