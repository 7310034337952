import { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import CartItem from "../CartItem";
import { CenteredDiv } from "../styles/Cart.styles";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { useWindowSize } from "../../hooks/uiHooks";
import {
  MAX_MOBILE_WIDTH,
  CART_PADDING_LEFT,
  CART_PADDING_RIGHT
} from "../../constants/";
import { useUserContext } from "../../hooks/userHooks";

/**
 *
 * @param {Array} cartItems
 */
function generateCartEntries(
  cart,
  updateCart,
  setTotal,
  onOrderPage = false,
  dark,
  setLoading
) {
  let elements = [];
  const cartItems = cart;
  if (!cartItems) return emptyCart();

  cartItems.forEach((cartItem) => {
    const productId = cartItem?.product.id;
    if (productId === undefined) return;
    elements.push(
      <CartItem
        dark={dark}
        key={`cartItem${cartItem?.product.id}`}
        data={cartItem.product}
        quantity={cartItem.qty}
        cart={cart}
        updateCart={updateCart}
        setTotal={setTotal}
        setLoading={setLoading}
        calculateSubTotal={calculateSubTotal}
        productId={cartItem?.product.id}
        onOrderPage={onOrderPage}
      />
    );
  });

  return elements?.length === 0 ? emptyCart() : elements;
}

function emptyCart() {
  return <CenteredDiv>No Items In Cart</CenteredDiv>;
}

function calculateSubTotal(cart) {
  let sum = 0;
  cart?.forEach((item) => {
    /**@todo rewrite for extensibility & add in quantity */
    sum += item.product.price * item.qty;
  });
  return sum;
}

// TODO Not clear how parameters singleProductPurchase and onOrderPage related. Need cleaning.
export default function CartDrawerContent({
  dark = true,
  singleProductPurchase = false,
  onCheckoutPage = false,
  onOrderPage = false
}) {
  const navigate = useNavigate();
  const { user, cart, updateCart, instantPurchase, setInstantPurchase } =
    useUserContext();

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const windowSize = useWindowSize();

  const styles = {
    cartLoader: {
      width:
        windowSize.windowWidth < MAX_MOBILE_WIDTH
          ? windowSize.windowWidth - CART_PADDING_LEFT - CART_PADDING_RIGHT - 4
          : "500px"
    }
  };

  useEffect(() => {
    if (singleProductPurchase) {
      setTotal(calculateSubTotal(instantPurchase));
    } else {
      setTotal(calculateSubTotal(cart));
    }
  }, [user.id, cart, total, instantPurchase, singleProductPurchase]);

  const handleConfirm = () => {
    setInstantPurchase([]);
    localStorage.setItem("LOCATION_PREV_PATH", window.location.pathname);
    navigate("/checkout");
  };

  return (
    <>
      {loading ? (
        <div style={styles.cartLoader}>
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={"50%"}
            width={50}
            className={"loader"}
          />
        </div>
      ) : (
        <Box
          id="CartDrawerBox"
          sx={{
            backgroundColor: dark ? "secondary.main" : "transparent",
            height: "100%",
            maxWidth: "80vw",
            minWidth: "30vw",
            width: "auto",
            // padding: "0 2rem 0 3rem",
            color: dark ? "lightText.main" : "darkText.main"
          }}>
          <Box
            sx={{
              height: "85%",
              overflow: "hidden",
              overflowY: "scroll",
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "tertiary.main"
              }
            }}>
            {singleProductPurchase === false
              ? generateCartEntries(
                  cart,
                  updateCart,
                  setTotal,
                  onOrderPage,
                  dark,
                  setLoading
                )
              : generateCartEntries(
                  instantPurchase,
                  setInstantPurchase,
                  setTotal,
                  true, // Not sure this logic, but true removes Counts of products on Checkout
                  dark,
                  setLoading
                )}
          </Box>

          <Box
            sx={{
              height: "20%"
            }}>
            {onOrderPage ? null : (
              <>
                <CenteredDiv sx={{ fontSize: "1.5rem", height: "50%" }}>
                  <strong>
                    Order Total:&nbsp;&nbsp;$
                    {(Math.round(total * 100) / 100).toFixed(2)}
                  </strong>
                </CenteredDiv>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                  {onCheckoutPage ||
                  cart?.length === 0 ||
                  total === 0 ? null : (
                    <Button
                      sx={{
                        width: "100%",
                        backgroundColor: "tertiary.main"
                      }}
                      onClick={handleConfirm}>
                      Proceed To Checkout
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
