import { Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/system";
import * as Router from "react-router-dom";

// function generateSubCategories(category, setDrawerOpen) {
//   const subCategories = category?.subCategories;

//   if (subCategories === undefined) return;

//   let elements = [];

//   subCategories.forEach((subCategory) => {
//     elements.push(
//       <Link
//         component={Router.Link}
//         to={`/category/${subCategory?.name}/${subCategory?.id}`}
//         underline="hover"
//         onClick={() => setDrawerOpen(false)}
//         sx={{
//           display: "block",
//           margin: "0 0 0.5rem 5%",
//           borderTop: "1px solid",
//           borderColor: (theme) => alpha(theme.palette.tertiary.main, 0.1),
//           ":first-of-type": {
//             borderTop: "none",
//           },
//         }}
//       >
//         {subCategory?.name}
//       </Link>
//     );
//   });

//   return elements;
// }

export default function CategoriesSlideOutItem(props) {
  const category = props.category;
  // const [openCategory, setOpenCategory] = [
  //   props.openCategory,
  //   props.setOpenCategory,
  // ];

  return (
    <Box
      component={Router.Link}
      to={`/category/${category?.name}/${category?.id}`}
      onClick={() => props.setDrawerOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "baseline",
        width: "100%",
        background: "none",
        boxShadow: "none",
        padding: "0.75rem 0 0.75rem 1rem",
        textDecoration: "none",
        ":hover": {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1)
        }
      }}>
      <Typography
        sx={{
          marginBottom: "0",
          textDecoration: "none",
          color: "lightText.main"
        }}>
        {category.name}
      </Typography>
    </Box>
  );
}
