import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPlayer from "react-player";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  PRODUCT_MESSAGE_STATUS_DELETED,
  UPLOADED_FILES_NODE_ID,
  VIDEO_FRAME_WIDTH,
  VIDEO_FRAME_HEIGHT,
  VIDEO_FRAME_FORMAT,
  VIDEO_FRAME_QUALITY
} from "../../constants/";
import { MessageService } from "../../services/api/general";
import { useSnackbar } from "notistack";

const styles = {
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0
  },
  videoContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 318,
    bgcolor: "background.paper",
    border: "1px solid #888",
    boxShadow: 24,
    p: 1
  }
};

const sx = {
  imageList: {
    width: 470,
    height: 50
  }
};

export default function MediaFileView(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [vpOpen, setVPOpen] = useState(false);
  const [videoParams, setVideoParams] = useState({
    uri: "",
    filename: "",
    index: -1
  });
  const [player, setPlayer] = useState(null);
  let media = props.mediaInfo;
  let isVideoFile = props.isVideoFile;
  let bin = [{ id: 1, name: "bin" }];

  useEffect(() => {
    async function fetchData() {
      if (isVideoFile && !vpOpen) await setVideoFrame();
    }
    fetchData();
  });

  const setVideoFrame = async () => {
    for (let [index, item] of media.entries()) {
      if (item.mimetype.match(/^video\/.*/) && !item.frame) {
        setVideoParams({
          uri: item.uri,
          filename: item.filename,
          index: index
        });
        setVPOpen(true);
        break;
      }
    }
  };

  const handleDragEnd = async (result) => {
    if (
      result.source &&
      result.source.droppableId === "media" &&
      result.destination
    ) {
      const item = media.splice(result.source.index, 1)[0];
      if (result.destination.droppableId === "media") {
        media.splice(result.destination.index, 0, item);
      } else if (result.destination.droppableId === "bin") {
        const message = await MessageService._composeMessage(
          item.uri,
          PRODUCT_MESSAGE_STATUS_DELETED
        );
        await MessageService._addMessage(UPLOADED_FILES_NODE_ID, `${message}`);
      }
      props.parentCallback(media);
    }
  };

  const showVideoPlayer = (videoInfo, index) => {
    setVideoParams({
      uri: videoInfo.uri,
      filename: videoInfo.filename,
      index: index
    });
    setVPOpen(true);
  };

  const closeVideoPlayer = () => {
    for (let item of media) {
      if (item.mimetype.match(/^video\/.*/) && !item.frame) {
        enqueueSnackbar("No Video Frame. Click on camera icon.", {
          variant: "warning"
        });
        return;
      }
    }
    isVideoFile = false;
    setVideoParams({
      uri: "",
      filename: "",
      index: -1
    });
    setVPOpen(false);
  };

  const createVideoFrame = () => {
    const frame = captureVideoFrame(
      player.getInternalPlayer(),
      VIDEO_FRAME_FORMAT,
      VIDEO_FRAME_QUALITY
    );
    media[videoParams.index].frame = frame.dataUri;
    setVPOpen(false);
  };

  const captureVideoFrame = (video, format, quality) => {
    if (typeof video === "string") {
      video = document.getElementById(video);
    }

    format = format || "jpeg";
    quality = quality || 0.92;

    if (!video || (format !== "png" && format !== "jpeg")) {
      return false;
    }

    var canvas = document.createElement("CANVAS");

    canvas.width = VIDEO_FRAME_WIDTH;
    canvas.height = VIDEO_FRAME_HEIGHT;

    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

    var dataUri = canvas.toDataURL("image/" + format, quality);
    var data = dataUri.split(",")[1];
    var mimeType = dataUri.split(";")[0].slice(5);

    var bytes = window.atob(data);
    var buf = new ArrayBuffer(bytes.length);
    var arr = new Uint8Array(buf);

    for (var i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    var blob = new Blob([arr], { type: mimeType });
    return { blob: blob, dataUri: dataUri, format: format };
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="media" direction="horizontal">
        {(provided) => (
          <Tooltip title="Drag & Drop the image to change an order">
            <ImageList
              style={styles.flexContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={sx.imageList}
              rows={1}
              cols={8}
              gap={8}>
              {media.map((item, index) => (
                <Draggable key={index} draggableId={item.uri} index={index}>
                  {(provided) => (
                    <ImageListItem
                      sx={
                        item.mimetype.match(/^video\/.*/) && {
                          border: 1,
                          borderColor: "#000"
                        }
                      }
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={item.uri}>
                      {
                        <Tooltip title={item.filename}>
                          {item.mimetype.match(/^image\/.*/) ? (
                            <img
                              style={{ width: "45px", height: "45px" }}
                              src={item.icon ? item.icon : item.uri}
                              alt="Product"
                              key={item.icon ? item.icon : item.uri}
                            />
                          ) : (
                            <IconButton
                              onClick={(event) => showVideoPlayer(item, index)}>
                              {item.frame ? (
                                <img
                                  style={{ width: "45px", height: "32px" }}
                                  src={item.frame}
                                  alt="videoFrame"
                                  key={item.frame}
                                />
                              ) : (
                                <PersonalVideoIcon></PersonalVideoIcon>
                              )}
                            </IconButton>
                          )}
                        </Tooltip>
                      }
                    </ImageListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ImageList>
          </Tooltip>
        )}
      </Droppable>
      <Droppable droppableId="bin" direction="horizontal">
        {(provided) => (
          <Tooltip title="Drag & Drop the image here to delete from the list">
            <ImageList
              style={styles.flexContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={sx.imageList}
              rows={1}
              cols={8}
              gap={8}>
              {bin.map((item, index) => (
                <Draggable key={item.id} draggableId={item.name} index={index}>
                  {(provided) => (
                    <ImageListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key="1">
                      {
                        <Tooltip title="Bin">
                          <IconButton style={{ padding: 0 }}>
                            <DeleteForeverIcon
                              style={{ fontSize: 40 }}></DeleteForeverIcon>
                          </IconButton>
                        </Tooltip>
                      }
                    </ImageListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ImageList>
          </Tooltip>
        )}
      </Droppable>
      <Modal
        open={vpOpen}
        onClose={closeVideoPlayer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styles.videoContainer}>
          <Box sx={{ textAlign: "right", m: 0 }}>
            <Typography variant="subtitle1">{videoParams.filename}</Typography>
            <Tooltip title="Frame">
              <IconButton onClick={createVideoFrame}>
                <AddAPhotoIcon></AddAPhotoIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={closeVideoPlayer}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </Tooltip>
          </Box>
          <ReactPlayer
            ref={(playerData) => {
              setPlayer(playerData);
            }}
            url={videoParams.uri}
            width="300px"
            height="175px"
            controls
            config={{
              file: {
                attributes: {
                  crossOrigin: "anonymous"
                }
              }
            }}
          />
        </Box>
      </Modal>
    </DragDropContext>
  );
}
