import SearchIcon from "@mui/icons-material/Search";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: "100px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "30ch"
      }
    }
  }
}));

function SearchModal(props) {
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      props.setOpen(false);
      navigate(`/search/${value}`);
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: ["90vw", "80vw", "50vw"],
          bgcolor: "background.paper",
          borderRadius: (theme) => theme.shape.borderRadius / 2,
          boxShadow: 24,
          p: 1
        }}>
        <Search sx={{ margin: 0 }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            autoFocus
            placeholder="Search…"
            inputProps={{ "aria-label": "search", "width:": "100%" }}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            onKeyDown={onKeyDownHandler}
            sx={{ width: "100%" }}
          />
        </Search>
      </Box>
    </Modal>
  );
}

export default SearchModal;
