import { MAX_FILE_SIZE, PRODUCT_MAX_MEDIA_FILES } from "../constants/";

export function checkFile(file, media) {
  const validationData = {
    error: false,
    message: "",
    variant: ""
  };

  if (file.size > MAX_FILE_SIZE) {
    validationData.error = true;
    validationData.message = `File size is more than ${MAX_FILE_SIZE} bytes`;
    validationData.variant = "error";
  } else if (media.length >= PRODUCT_MAX_MEDIA_FILES) {
    validationData.error = true;
    validationData.message = `No more than ${PRODUCT_MAX_MEDIA_FILES} uploaded files`;
    validationData.variant = "warning";
  }

  return validationData;
}
