import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import MainContainer from "../components/MainContainer";
import Header from "../components/NavigationBar/Header";
import Product from "../components/Product";
import { CategoryContext } from "../context/Contexts";
import { ProductsService } from "../services/api/general";
import * as Router from "react-router-dom";
import { alpha } from "@mui/material/styles";
import productImage from "../components/Checkout/ProductImage";
import { useUserContext } from "../hooks/userHooks";

function Subcategory() {
  const [categories] = useContext(CategoryContext);
  const [products, setProducts] = useState([]);
  const [done, setDone] = useState(false);
  const { isLoggedIn } = useUserContext();
  const params = useParams();

  useEffect(
    () => {
      setDone(false);
      const categoryName = params?.categoryName ?? "";
      const categoryId = params?.categoryId ?? "";
      if (categoryName === "" || categoryId === "") return;

      ProductsService._getProduct(params?.categoryId).then((res) => {
        let formattedProducts = [];
        res.forEach((item) => {
          if (!!item.description) {
            let fields = JSON.parse(item.description);
            let newProduct = {
              name: fields.name,
              description: fields.description,
              price: fields.price,
              picture: productImage(fields),
              id: item.id
            };
            formattedProducts.push(newProduct);
          }
        });
        formattedProducts = formattedProducts.slice(1);
        setProducts(formattedProducts);
        setDone(true);
      });
    },
    [categories, params?.categoryName, params?.categoryId],
    done
  );

  const remainingProducts = products.map((item) => {
    return (
      <div className="individual-product">
        <Product
          key={item.id}
          id={item.id}
          name={item.name}
          description={item.description}
          price={item.price}
          picture={item.picture}
        />
      </div>
    );
  });

  return (
    <div>
      <Header />
      <MainContainer>
        {!done ? (
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={100}
            width={100}
            className={"loader"}
          />
        ) : products === undefined ||
          products === null ||
          products.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "30vh"
            }}>
            <Typography
              variant="h7"
              fontWeight={400}
              sx={{ height: "2rem", textAlign: "center" }}>
              Sorry! No Items Available.
            </Typography>
            <br />
            <br />
            <Typography
              variant="h7"
              fontWeight={420}
              sx={{ height: "2rem", textAlign: "center" }}>
              Have something you'd like to sell?
            </Typography>
            <br />
            <br />
            <Button
              component={Router.Link}
              to={isLoggedIn ? `/createproduct` : `/signin`}
              sx={{
                borderRadius: "none",
                border: "1px solid",
                color: "darkText.main",
                width: "10rem",
                margin: "auto",
                ":hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.tertiary.main, 0.8)
                }
              }}>
              Add A Product
            </Button>
          </Box>
        ) : (
          <>
            <Typography
              to="/"
              fontFamily="'Inter', sans-serif;"
              variant="h6"
              fontWeight="600"
              noWrap
              component="div"
              color="black"
              sx={{ flexGrow: 2, marginLeft: 3, paddingTop: 2 }}>
              {params?.categoryName}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                padding: "1rem",
                justifyContent: "center"
              }}>
              {remainingProducts}
            </Box>
          </>
        )}
      </MainContainer>
    </div>
  );
}

export default Subcategory;
