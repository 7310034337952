import React, { useEffect, useState } from "react";
import MainContainer from "../components/MainContainer";
import Header from "../components/NavigationBar/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useUserContext } from "../hooks/userHooks";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";

const columns = [
  {
    id: "picture",
    label: "",
    align: "center"
  },
  {
    id: "name",
    label: "Product Name",
    align: "center"
  },
  {
    id: "price",
    label: "Price",
    align: "center"
  },
  {
    id: "delete",
    label: "",
    align: "center"
  },
  {
    id: "addToCart",
    label: "",
    align: "center"
  }
];

function Wishlist() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { cart, updateCart, wishlist, removeFromWishlist } = useUserContext();
  const [done, setDone] = useState(false);
  const [formattedWishlist, setFormattedWishlist] = useState(false);

  console.log("wishlist");
  console.log(wishlist);

  useEffect(() => {
    console.log("FORMATTING WISHLIST");
    console.log(wishlist);
    setDone(false);
    let formatWishlist = wishlist.map((item) => {
      let product = item.product;
      let newProduct = {
        id: product.id,
        name: product.name,
        description: product.description,
        price: product.price,
        picture: product.picture
      };
      return newProduct;
    });
    setFormattedWishlist(formatWishlist);
    setDone(true);
  }, [wishlist]);

  const clickHandler = (index) => {
    navigate(`/product/${index}`);
  };

  const removeItemFromWishlist = (id) => {
    removeFromWishlist(id);
  };

  const addItemToCart = (row) => {
    let inCart = false;
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].product.id === row.id) {
        inCart = true;
      }
    }
    if (inCart) {
      enqueueSnackbar("Item Already In Cart", { variant: "error" });
    } else {
      removeItemFromWishlist(row.id);

      let product = {
        id: row.id,
        description: row.description,
        name: row.name,
        picture: row.picture,
        price: row.price,
        seller: row.sellerName,
        subcategoryId: row.subCategoryId
      };

      updateCart(product, 1);
      enqueueSnackbar("Item Added To Cart!", { variant: "success" });
    }
  };

  return (
    <div>
      <Header />
      <MainContainer>
        {!done ? (
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={100}
            width={100}
            className={"loader"}
          />
        ) : (
          <div>
            <h1
              className="order-history-title"
              style={styles.orderHistoryTitle}>
              Your Wishlist
            </h1>
            <div className="order-history-main" style={styles.orderHistory}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer style={styles.orderHistoryTable}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}>
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formattedWishlist.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}>
                            <TableCell
                              align={columns[0].align}
                              onClick={() => clickHandler(row.id)}>
                              {row.picture ? (
                                <Card sx={{ maxWidth: 200 }}>
                                  <CardMedia
                                    component="img"
                                    image={row.picture}
                                    alt={row.picture}
                                    sx={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      objectFit: "contain",
                                      pt: 0
                                    }}
                                  />
                                </Card>
                              ) : (
                                <IconButton>
                                  <PersonalVideoIcon></PersonalVideoIcon>
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              align={columns[1].align}
                              onClick={() => clickHandler(row.id)}>
                              {row.name}
                            </TableCell>
                            <TableCell
                              align={columns[2].align}
                              onClick={() => clickHandler(row.id)}>
                              {row.price}
                            </TableCell>
                            <TableCell align={columns[3].align}>
                              <IconButton
                                onClick={() => removeItemFromWishlist(row.id)}
                                aria-label="delete from wishlist">
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell align={columns[4].align}>
                              <IconButton
                                onClick={() => addItemToCart(row)}
                                aria-label="move to shopping cart">
                                <AddShoppingCartIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        )}
      </MainContainer>
    </div>
  );
}

const styles = {
  orderHistory: {
    display: "flex",
    padding: "25px",
    justifyContent: "center",
    alignItems: "center"
  },
  orderHistoryTable: {
    height: "auto"
  },
  orderHistoryTitle: {
    padding: "25px"
  },
  tableHeaderRow: {
    backgroundColor: "#d3d3d3"
  }
};

export default Wishlist;
