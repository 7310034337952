import React, { createContext, useState, useEffect, useCallback } from "react";
import decode from "jwt-decode";
import { CartService, WishlistService } from "../services/api/general";
import {
  isLoggedInClientSide,
  MINUTE_MS,
  validateTokenKeys
} from "../utils/auth";

function getDefaultUser() {
  return {
    id: 0,
    fullName: "",
    email: ""
  };
}

// Placeholders for initial user context
export const UserContext = createContext({
  user: getDefaultUser(),
  setUser: () => {},
  isLoggedIn: isLoggedInClientSide(),
  logout: () => {},
  authenticateUser: () => {},
  cart: [],
  clearCart: () => {},
  updateCart: () => {},
  wishlist: [],
  removeFromWishlist: () => {},
  addToWishlist: () => {},
  instantPurchase: [],
  setInstantPurchase: () => {}
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(getDefaultUser());

  // Synchronize isLoggedIn state with the presence and validity of the JWT
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInClientSide());

  // Other state and setters as needed
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [instantPurchase, setInstantPurchase] = useState([]);

  // A function that resets all user data to default
  const resetAllUserData = useCallback(() => {
    localStorage.removeItem("TOKEN");
    setUser(getDefaultUser());
    setCart([]);
    setWishlist([]);
    setInstantPurchase([]);
    setIsLoggedIn(false);
  }, []);

  const logout = () => {
    resetAllUserData();
  };

  const authenticateUser = (token) => {
    if (!token || !validateTokenKeys(token)) {
      logout();
      return false;
    }

    console.log("authenticateUser");
    localStorage.setItem("TOKEN", token);
    setIsLoggedIn(true);

    return true;
  };

  const clearCart = async () => {
    const previousCart = cart;

    // Optimistic UI update
    setCart([]);

    try {
      await CartService._clearCart();
    } catch (error) {
      // Revert to previous cart on error
      setCart(previousCart);
      console.error("Failed to clear the cart:", error);
    }
  };

  const updateCart = async (product, quantity) => {
    console.log("product to ADD:");
    console.log(product);
    const previousCart = cart;

    // Find the product in the cart, if it exists
    const existingProductIndex = cart.findIndex(
      (item) => item.product.id === product.id
    );

    let newCart;
    if (existingProductIndex !== -1) {
      // Product exists, update the quantity
      console.log("Product exists, update the quantity");
      if (quantity === 0) {
        // Remove the item if the quantity is 0
        console.log("Remove the item if the quantity is 0");
        newCart = cart.filter((item, index) => index !== existingProductIndex);
      } else {
        // Update the item quantity
        console.log("Update the item quantity");
        newCart = cart.map((item, index) =>
          index === existingProductIndex ? { ...item, qty: quantity } : item
        );
      }
    } else {
      // Product does not exist, add as new item
      console.log("Product does not exist, add as new item");
      newCart = [...cart, { product, qty: quantity }];
    }

    // Optimistic UI update
    setCart(newCart);

    try {
      await CartService._updateCartItem(product.id, quantity);
    } catch (error) {
      // Revert to previous cart on error
      setCart(previousCart);
      console.error("Failed to add item to cart:", error);
    }
  };

  const addToWishlist = async (product) => {
    console.log("product to ADD to Wishlist:");
    console.log(product);
    const previousWishlist = wishlist;

    // Find the product in the cart, if it exists
    const existingProductIndex = wishlist.findIndex(
      (item) => item.product.id === product.id
    );

    let newWishlist;
    if (existingProductIndex !== -1) {
      // Product exists, update the quantity
      console.log("Product exists in wishlist, do nothing");
    } else {
      // Product does not exist, add as new item
      console.log("Product does not exist in wishlist, add as new item");
      newWishlist = [...wishlist, { product }];
    }

    // Optimistic UI update
    setWishlist(newWishlist);

    try {
      await WishlistService._addToUsersWishlist(product.id);
    } catch (error) {
      // Revert to previous cart on error
      setWishlist(previousWishlist);
      console.error("Failed to add item to wishlist:", error);
    }
  };

  const removeFromWishlist = async (productId) => {
    console.log("product to Remove to Wishlist:");
    console.log(productId);
    const previousWishlist = wishlist;

    // Find the product in the cart, if it exists
    const existingProductIndex = wishlist.findIndex(
      (item) => item.product.id === productId
    );

    let newWishlist;
    if (existingProductIndex !== -1) {
      console.log("Product exists in wishlist, removing...");
      newWishlist = wishlist.filter(
        (item, index) => index !== existingProductIndex
      );
    } else {
      // Product does not exist, add as new item
      console.log("Product does not exist in wishlist, skipping");
      return;
    }

    // Optimistic UI update
    setWishlist(newWishlist);

    try {
      await WishlistService._removeFromUsersWishlist(productId);
    } catch (error) {
      // Revert to previous cart on error
      setWishlist(previousWishlist);
      console.error("Failed to add item to wishlist:", error);
    }
  };

  useEffect(() => {
    // Checked that token is still valid for loggedIn user
    const interval = setInterval(() => {
      if (isLoggedIn && !isLoggedInClientSide()) {
        resetAllUserData();
        window.location.reload(false);
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // Unmount cleanup
  }, [resetAllUserData]);

  useEffect(() => {
    // Load user data when logged in
    if (isLoggedIn) {
      //console.log("TOKEN in Storage0");
      //console.log(localStorage.getItem("TOKEN"));
      const loggedInUser = localStorage.getItem("TOKEN");
      if (loggedInUser) {
        const decodedToken = decode(loggedInUser);

        // Update user state with data from token
        setUser((prevState) => ({
          ...prevState,
          id: decodedToken.data.id,
          email: decodedToken.data.email,
          fullName: decodedToken.data.fullName
        }));

        // Load cart
        CartService._getCart()
          .then((cart) => {
            console.log("Resolving Cart from Db again");
            console.log(cart);
            setCart(cart);
          })
          .catch((err) => {
            resetAllUserData();
            console.log("Failed to load card data", err);
          });

        // Load wishlist
        WishlistService._getUsersWishlist()
          .then((wishlist) => {
            console.log("wishlist in user provider");
            console.log(wishlist);
            setWishlist(wishlist);
          })
          .catch((err) => {
            resetAllUserData();
            console.log("Failed to load wishlist data", err);
          });
      }
    }
  }, [user.id, isLoggedIn, resetAllUserData]);

  // Define a value object that keeps the user data and setter methods together
  const value = {
    user,
    setUser,
    isLoggedIn,
    logout,
    authenticateUser,
    // Include other user-related properties and their setters
    cart,
    clearCart,
    updateCart,
    wishlist,
    addToWishlist,
    removeFromWishlist,
    instantPurchase,
    setInstantPurchase
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
