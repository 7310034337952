import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/NavigationBar/Header";
import CartDrawerContent from "../components/Drawers/CartDrawerContent";
import { alpha } from "@mui/material/styles";
import { useUserContext } from "../hooks/userHooks";

function OrderConfirmation() {
  let location = useLocation();
  const [user, clearCart] = useUserContext();
  let shippingInfo = location.state.shippingInfo;
  let orderNumber = location.state.orderNumber;
  let instantPurchase = location.state.instantPurchase;
  const [singleProduct] = useState(
    instantPurchase !== undefined && instantPurchase.length !== 0
  );
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF9900",
        contrastText: "#FFFFFF"
      },
      secondary: {
        main: "#3E3E3E",
        contrastText: "#FFFFFF"
      }
    }
  });

  useEffect(() => {
    if (
      user.instantPurchase === undefined ||
      user.instantPurchase.length === 0
    ) {
      clearCart();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          component="main"
          maxWidth="md"
          sx={{
            mb: 4,
            height: "800px"
          }}>
          <Paper
            variant="outlined"
            sx={{
              my: {
                xs: 3,
                md: 6
              },
              p: {
                xs: 2,
                md: 3
              }
            }}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              fontFamily="'Inter', sans-serif;"
              fontWeight="700"
              color="var(--tertiary-colour)">
              Order Confirmation{" "}
            </Typography>{" "}
            <div className="order-details" style={styles.orderDetails}>
              <Box>
                <div className="order-header" style={styles.orderHeader}>
                  <Typography variant="h5"> Order Details: </Typography>{" "}
                </div>{" "}
                <div>
                  <Typography> Order Number: {orderNumber}</Typography>{" "}
                </div>{" "}
                <Box
                  sx={{
                    padding: "10px",
                    border: "1px solid",
                    borderColor: (theme) =>
                      alpha(theme.palette.secondary.main, 0.2),
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                  }}>
                  <CartDrawerContent
                    onOrderPage={true}
                    singleProductPurchase={singleProduct}
                    dark={false}
                  />
                </Box>
              </Box>{" "}
            </div>{" "}
            <div className="shipping-info" style={styles.shippingInfo}>
              <Box>
                <div className="shipping-header" style={styles.shippingHeader}>
                  <Typography variant="h5"> Shipping Address: </Typography>{" "}
                </div>{" "}
                <Typography variant="h6">
                  {" "}
                  {shippingInfo.firstName} {shippingInfo.lastName}{" "}
                </Typography>{" "}
                <Typography> {shippingInfo.email} </Typography>{" "}
                <Typography> {shippingInfo.address} </Typography>{" "}
                <Typography>
                  {" "}
                  {shippingInfo.city}, {shippingInfo.province},{" "}
                  {shippingInfo.country}{" "}
                </Typography>{" "}
                <Typography> {shippingInfo.postalCode} </Typography>{" "}
              </Box>{" "}
            </div>{" "}
          </Paper>{" "}
        </Container>{" "}
      </ThemeProvider>{" "}
    </>
  );
}
const styles = {
  shippingInfoDiv: {
    marginBottom: "100px",
    padding: "10px"
  }
};
export default OrderConfirmation;
