import { createContext } from "react";
// import { tempCategories, tempTopCategories } from "../data/dummy";

/**@todo change based on actual data */
export const CategoryContext = createContext({
  categories: {
    all: [],
    top: []
  }
});

export const HomeContext = createContext({
  categoriesWithProducts: []
});
