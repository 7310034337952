import { useState, useEffect, memo, useCallback, useRef } from "react";
import { LoginService } from "../services/api/general";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  LOGIN_SSO_URL,
  LOGIN_SSO_ROUTE,
  LOGIN_SSO_WINDOW_NAME,
  LOGIN_SSO_WINDOW_WIDTH,
  LOGIN_SSO_WINDOW_HEIGHT,
  SIGNUP_SSO_WINDOW_HEIGHT,
  LOGIN_SSO_RESPONSE_TYPE
} from "../constants";
import { useUserContext } from "../hooks/userHooks";

const LoginSSO = ({ ssoActivated, ssoRoute }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { authenticateUser } = useUserContext();
  const loginWindowRef = useRef(null);
  const previousSsoActivated = useRef(ssoActivated); // Храним предыдущее значение
  const activeEventListener = useRef(false);

  const popupCenterParams = useCallback((width, height) => {
    return {
      left: window.screen.width / 2 - width / 2,
      top: window.screen.height / 2 - height / 2
    };
  }, []);

  const finishLoginSSO = useCallback(
    async (message) => {
      console.log("loginWindowRef.current is null?", loginWindowRef.current);
      // null check is preventing from error in console. It is passible because parent component can re-render before we close it here after successful login
      if (loginWindowRef.current && !loginWindowRef.current.closed) {
        loginWindowRef.current.close();
        enqueueSnackbar(message.title, { variant: message.type });
      }

      if (message.type === "error") {
        window.location.reload();
      }
    },
    [loginWindowRef.current]
  );

  const openLoginPopup = useCallback(() => {
    const currentTimestamp = Date.now();
    const loginURL = `${LOGIN_SSO_URL}/${ssoRoute}?timestamp=${currentTimestamp}`;

    console.log("ssoRoute:", ssoRoute);
    console.log("loginURL:", loginURL);
    if (loginWindowRef.current && !loginWindowRef.current.closed) {
      console.log("focusing again on login popup");
      loginWindowRef.current.focus();
      return;
    }

    const windowHeight =
      ssoRoute === LOGIN_SSO_ROUTE
        ? LOGIN_SSO_WINDOW_HEIGHT
        : SIGNUP_SSO_WINDOW_HEIGHT;

    const popupParams = popupCenterParams(LOGIN_SSO_WINDOW_WIDTH, windowHeight);

    loginWindowRef.current = window.open(
      loginURL,
      LOGIN_SSO_WINDOW_NAME,
      `width=${LOGIN_SSO_WINDOW_WIDTH},
            height=${windowHeight},
            toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no,
            top=${popupParams.top}, left=${popupParams.left}`
    );
  }, [loginWindowRef.current, ssoRoute, popupCenterParams]);

  const setupReturnLoginEventListener = useCallback(() => {
    // Если обработчик еще не установлен, добавляем его
    if (!activeEventListener.current) {
      console.log("Setting up event listener for messages.");
      window.addEventListener("message", onLoginMessage);
      activeEventListener.current = true; // Обозначаем, что обработчик установлен
    }
  }, [activeEventListener.current]);

  useEffect(() => {
    setupReturnLoginEventListener();

    return () => {
      if (activeEventListener.current) {
        window.removeEventListener("message", onLoginMessage);
        activeEventListener.current = false;
      }
    };
  }, []);

  useEffect(() => {
    console.log("useEffect of LoginSSO with props.ssoActivated:", ssoActivated);
    console.log("previousSsoActivated.current:", previousSsoActivated.current);

    if (ssoActivated !== previousSsoActivated.current && !!ssoRoute) {
      console.log("inside again");
      openLoginPopup();
      previousSsoActivated.current = ssoActivated; // For one click on login button we allow to open popup only once if previous not closed yet.
    }
  }, [openLoginPopup, ssoActivated, ssoRoute]);

  const onLoginMessage = useCallback(
    async (event) => {
      console.log("EventListener event:");
      console.log(event);
      // Make sure we only handle message which comes from the expected origin (iLiveMyLife)
      if (event.origin !== LOGIN_SSO_URL) {
        console.log("Event is not ours?", event.origin);
        return;
      }

      const eventDataType = JSON.parse(event?.data?.type);
      if (eventDataType?.name === "loginError") {
        await finishLoginSSO({ title: "Login Failed", type: "error" });
        return;
      } else if (eventDataType?.name === "signupError") {
        await finishLoginSSO({ title: "Signup Failed", type: "error" });
        return;
      } else if (eventDataType?.name === LOGIN_SSO_RESPONSE_TYPE) {
        // Process the authentication token here and close the popup window
        const response = await LoginService._loginSSO(event.data.data);

        if (!response) {
          eventDataType.name = "LoginIssue";
          eventDataType.message = "authentication token issue";
          eventDataType.status = "";
          await finishLoginSSO({
            title: "Issue logging in. Please try Again in a moment.",
            type: "warning"
          });
          return;
        }

        console.log("Token is coming from data:");
        console.log(response.data);
        if (authenticateUser(response.data.token)) {
          await finishLoginSSO(
            {
              title: "Successfully Logged In",
              type: "success"
            },
            eventDataType
          );
        } else {
          eventDataType.name = "LoginIssue";
          eventDataType.message = "token validation issue";
          eventDataType.status = "";

          await finishLoginSSO({
            title: "Issue logging in. Token Validation Failed.",
            type: "error"
          });
        }
      }
    },
    [finishLoginSSO, authenticateUser]
  );

  return null;
};

export default memo(LoginSSO);
