import React from "react";
import { Box, Typography } from "@mui/material";
import CartDrawerContent from "./Drawers/CartDrawerContent";
import { alpha } from "@mui/material/styles";

function Subtotal(props) {
  console.log("Subtotal props:");
  console.log(props);
  let shippingInfo = props.shippingInfo;
  return (
    <div className="main-subtotal-div" style={styles.mainSubtotalDiv}>
      <Box
        style={{ height: "300px" }}
        sx={{
          padding: "0 10px",
          border: "1px solid",
          borderColor: (theme) => alpha(theme.palette.secondary.main, 0.2),
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
        }}>
        <CartDrawerContent
          onCheckoutPage={true}
          singleProductPurchase={props.singleProductPurchase}
          dark={false}
          sx={{
            padding: 0
          }}
        />
      </Box>
      <div className="shipping-total" style={styles.shipping}>
        Shipping: $10.00
      </div>
      <div className="shipping-info-div" style={styles.shippingInfoDiv}>
        <div className="shipping-header" style={styles.shippingHeader}>
          <Typography variant="h5">Shipping Address:</Typography>
        </div>
        {props.shippingInfoAddingWay === "byform" ? (
          <div className="shipping-info" style={styles.shippingInfo}>
            <Box
              style={{ height: "100px", overflow: "auto" }}
              sx={{
                padding: "0 10px",
                border: "1px solid #FF0000",
                borderColor: (theme) =>
                  alpha(theme.palette.secondary.main, 0.2),
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
              }}>
              <Typography variant="h6">
                {shippingInfo.firstName} {shippingInfo.lastName}
              </Typography>
              <Typography>{shippingInfo.email}</Typography>
              <Typography>{shippingInfo.address}</Typography>
              <Typography>
                {shippingInfo.city}, {shippingInfo.province},{" "}
                {shippingInfo.country}
              </Typography>
              <Typography>{shippingInfo.postalCode}</Typography>
            </Box>
          </div>
        ) : (
          <div>Shipping Address will be selected from the Pay System</div>
        )}
      </div>
    </div>
  );
}

const styles = {
  mainSubtotalDiv: {
    width: "97%",
    height: "90%",
    padding: "10px",
    borderRadius: "1%",
    justifyContent: "center",
    color: "darkText.main"
  },
  shipping: {
    margin: "10px"
  },
  orderTotal: {
    margin: "10px"
  },
  line: {
    width: "100%",
    marginBottom: "20px",
    marginTop: "30px",
    marginLeft: "0px",
    color: "white"
  },
  payButtonDiv: {
    display: "flex",
    width: "100%",
    height: "300px",
    alignSelf: "center",
    justifyContent: "center"
  },
  payButton: {
    backgroundColor: "#FF9900",
    width: "65%",
    alignSelf: "center"
  },
  shippingInfoDiv: {
    marginBottom: "100px",
    padding: "10px"
  }
};

export default Subtotal;
