import Subtotal from "../Subtotal";

function Payment(props) {
  return (
    <Subtotal
      singleProductPurchase={props.singleProductPurchase}
      subtotal={props.subtotal}
      shippingInfo={props.shippingInfo}
      shippingInfoAddingWay={props.shippingInfoAddingWay}
    />
  );
}
export default Payment;
