import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Link } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { alpha, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "@mui/material";
import { useState, useEffect } from "react";
import * as Router from "react-router-dom";
import MenuDrawerContent from "../Drawers/MenuDrawerContent";
import PageDrawer from "../Drawers/PageDrawer";
import NavTabs from "./HeaderTabs";
import SearchModal from "./SearchModal";
import { useLocation, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import SignUpIcon from "@mui/icons-material/AppRegistration";
import Tooltip from "@mui/material/Tooltip";
import LoginSSO from "../../utils/LoginSSO";
import { LOGIN_SSO_ROUTE, SIGNUP_SSO_ROUTE, NODE_ENV } from "../../constants/";
import { useUserContext } from "../../hooks/userHooks";

const StyledDivider = styled(Divider)(({ theme }) => ({
  background: "var(--tertiary-colour)"
}));

function SearchAppBar() {
  const [openSearch, setOpenSearch] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const [primaryRoute, setPrimaryRoute] = useState("");
  const [secondaryRoute, setSecondaryRoute] = useState("");
  const [subRoute, setSubRoute] = useState("");
  const navigate = useNavigate();
  const [ssoActivated, setSsoActivated] = useState(0);
  const [ssoRoute, setSsoRoute] = useState("");
  const { isLoggedIn } = useUserContext();

  useEffect(() => {
    if (location.pathname.split("/")[1]) {
      setPrimaryRoute(location.pathname.split("/")[1]);
      if (
        location.pathname.split("/")[1] === "category" ||
        location.pathname.split("/")[1] === "subcategory"
      ) {
        setSubRoute(
          location.pathname.split("/")[1] +
            "/" +
            location.pathname.split("/")[2] +
            "/" +
            location.pathname.split("/")[3]
        );
      } else if (
        location.pathname.split("/")[1] === "product" ||
        location.pathname.split("/")[1] === "search"
      ) {
        setSubRoute(
          location.pathname.split("/")[1] +
            "/" +
            location.pathname.split("/")[2]
        );
      } else {
        setSubRoute(location.pathname.split("/")[1]);
      }
    }
    if (location.pathname.split("/")[2]) {
      if (location.pathname.split("/")[1] !== "product") {
        setSecondaryRoute(
          location.pathname.split("/")[2].replaceAll("%20", "")
        );
      }
    }
  }, [location]);

  useEffect(() => {}, [openSearch]);

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: "99"
      }}>
      <AppBar
        position="static"
        sx={{
          padding: "0 2%",
          bgcolor: "var(--secondary-colour)"
        }}>
        <Toolbar
          padding="none"
          disableGutters
          sx={{ justifyContent: "space-between" }}>
          <Link component={Router.Link} to="/" underline="none">
            <Typography
              to="/"
              fontFamily="'Inter', sans-serif;"
              variant="h4"
              fontWeight="700"
              noWrap
              component="div"
              color="var(--tertiary-colour)"
              sx={{ flexGrow: 2 }}>
              Basic Needs
            </Typography>
          </Link>
          {/* <Breadcrumbs
            aria-label="breadcrumb"
            style={{ color: "white", fontSize: "x-large" }}>
            {primaryRoute && (
              <Link underline="hover" color="inherit" href="/">
                Home
              </Link>
            )}

            {primaryRoute && (
              <Link underline="hover" color="inherit" href={`/${subRoute}`}>
                {primaryRoute}
              </Link>
            )}
            {secondaryRoute && (
              <Link underline="hover" color="inherit" href={location.pathname}>
                {secondaryRoute}
              </Link>
            )}
          </Breadcrumbs> */}

          <Box
            sx={{
              display: "flex",
              gap: "0.5rem"
            }}>
            <Button
              onClick={() => setOpenSearch(true)}
              sx={{
                minWidth: "2rem"
              }}>
              <SearchIcon
                sx={{
                  color: "tertiary.main"
                }}
              />
            </Button>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: (theme) => alpha(theme.palette.tertiary.main, 0.2)
              }}
            />
            {isLoggedIn ? (
              <Button
                onClick={() => setOpenMenu(true)}
                sx={{
                  minWidth: "2rem"
                }}>
                <MenuIcon
                  sx={{
                    color: "tertiary.main"
                  }}
                />
              </Button>
            ) : (
              <Box>
                <Button
                  sx={{
                    minWidth: "2rem"
                  }}
                  onClick={() => {
                    setSsoRoute(SIGNUP_SSO_ROUTE);
                    setSsoActivated((ssoActivated) => ssoActivated + 1);
                  }}>
                  <Tooltip title="Signup at iLiveMyLife">
                    <SignUpIcon style={{ color: "#FF9900" }} />
                  </Tooltip>
                </Button>
                <Button
                  sx={{
                    minWidth: "2rem"
                  }}
                  onClick={() => {
                    if (NODE_ENV.toLowerCase() === "production") {
                      setSsoRoute(LOGIN_SSO_ROUTE);
                      setSsoActivated((ssoActivated) => ssoActivated + 1);
                    } else {
                      navigate("/signin");
                    }
                  }}>
                  <Tooltip title="SignIn">
                    <LoginIcon style={{ color: "#FF9900" }} />
                  </Tooltip>
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
        <StyledDivider />
        <Toolbar disableGutters>
          <NavTabs />
        </Toolbar>
      </AppBar>
      <SearchModal open={openSearch} setOpen={setOpenSearch} />
      <PageDrawer direction="right" open={openMenu} setOpen={setOpenMenu}>
        <MenuDrawerContent />
      </PageDrawer>
      <LoginSSO ssoActivated={ssoActivated} ssoRoute={ssoRoute} />
    </Box>
  );
}

export default SearchAppBar;
