import Box from "@mui/material/Box";

export default function MainContainer(props) {
  return (
    <Box
      id="main-container"
      sx={{
        overflow: "hidden",
        position: "relative",
        maxWidth: "100vw",
        width: {
          sm: "100vw",
          md: "80vw"
        },
        minHeight: "100vh",
        boxShadow:
          "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
        backgroundColor: "primary.main",
        margin: "auto",
        "&::-webkit-scrollbar": {
          width: 4
        },
        "&::-webkit-scrollbar-track": {
          background: "none"
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "tertiary.main",
          borderRadius: 2,
          border: "10px solid transparent"
        }
      }}>
      {props.children}
    </Box>
  );
}
