import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainContainer from "../components/MainContainer";
import Header from "../components/NavigationBar/Header";
import Product from "../components/Product";
import { ProductsService } from "../services/api/general";
import ReactLoading from "react-loading";
import { Box, Typography } from "@mui/material";

function Search() {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [done, setDone] = useState(undefined);
  const params = useParams();

  useEffect(() => {
    setDone(false);
    const search = params?.search ?? "";
    if (search === "") return;
    setSearchQuery(params?.search);
    ProductsService._searchProducts(params?.search).then((res) => {
      let formattedProducts = res.map((item) => {
        let fields = JSON.parse(item.description);
        let newProduct = {
          name: fields.name,
          description: fields.description,
          price: fields.price,
          picture: fields.media?.[0].uri,
          id: item.id
        };
        return newProduct;
      });
      setDone(true);
      setProducts(formattedProducts);
    });
  }, [params?.search]);

  const remainingProducts = products.map((item) => {
    return (
      <div className="individual-product" key={item.id}>
        <Product
          key={item.id}
          id={item.id}
          name={item.name}
          description={item.description}
          price={item.price}
          picture={item.picture}
        />
      </div>
    );
  });

  return (
    <div>
      <Header />
      <MainContainer>
        {!done ? (
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={100}
            width={100}
            className={"loader"}
          />
        ) : products === undefined ||
          products === null ||
          products.length === 0 ? (
          <Box
            sx={{
              paddingTop: "30vh"
            }}>
            <Typography
              variant="h6"
              fontWeight={400}
              sx={{ height: "2rem", textAlign: "center" }}>
              No Items Available.
            </Typography>
          </Box>
        ) : (
          <div>
            <h1>{`Search for "${searchQuery}"`}</h1>
            <div id="category-page-product-container">{remainingProducts}</div>
          </div>
        )}
      </MainContainer>
    </div>
  );
}

export default Search;
