import React from "react";
import Product from "../Product";

function SimilarProduct({ product }) {
  return (
    <div className="category-box-container" style={styles.styledText}>
      <div className="category-scroller-header">
        <h2>Similar Products</h2>
      </div>
      <div className="category-product-container">
        {product?.map((item) => {
          return (
            <div className="individual-product" key={item.id}>
              <Product
                id={item.id}
                name={item.name}
                description={item.description}
                price={item.price}
                picture={item.picture}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

const styles = {
  styledText: {
    fontFamily: "'Inter', sans-serif",
    marginLeft: "30px",
    marginRight: "30px"
  }
};

export default SimilarProduct;
