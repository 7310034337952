import React, { useEffect, useState } from "react";
import MainContainer from "../components/MainContainer";
import Header from "../components/NavigationBar/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";
import { SellerService, ProductsService } from "../services/api/general";
import ReactLoading from "react-loading";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import MediaFileView from "../components/SellerProduct/MediaFileView";
import { useSnackbar } from "notistack";
import { checkFile } from "../validations/files";
import { UPLOADED_FILES_NODE_ID, APP_NAME } from "../constants/";
import productImage from "../components/Checkout/ProductImage";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    id: "picture",
    label: "",
    align: "center"
  },
  {
    id: "name",
    label: "Product Name",
    align: "center"
  },
  {
    id: "price",
    label: "Price",
    align: "center"
  },
  {
    id: "actions",
    label: "Actions",
    align: "center"
  }
];

function ProductManagement() {
  const [products, setProducts] = useState([]);
  const [done, setDone] = useState(true);
  const [modal, setModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editPrice, setEditPrice] = useState(0);
  const [editMedia, setEditMedia] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState(null);
  const [isVideoFile, setIsVideoFile] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const fetchProducts = () => {
    setDone(false);
    SellerService._getSellerProducts().then((res) => {
      let formattedResponse = [];
      res.forEach((item) => {
        if (!!item.description) {
          let fields = JSON.parse(item.description);
          let newProduct = {
            name: fields.name,
            description: fields.description,
            price: fields.price,
            media: fields.media,
            id: item.id
          };
          formattedResponse.push(newProduct);
        }
      });
      setDone(true);
      setProducts(formattedResponse);
    });
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const Edit = () => {
    if (!editId) return;
    setDone(false);
    ProductsService._editProduct({
      id: editId,
      name: editName,
      price: editPrice,
      description: editDescription,
      media: editMedia
    })
      .then(() => {
        setDone(true);
        const temp = products.filter((item) => {
          if (item.id === editId) {
            item.name = editName;
            item.price = editPrice;
            item.description = editDescription;
            item.media = editMedia;
          }
          return item;
        });
        setProducts(temp);
        setModal(false);
      })
      .catch((err) => {
        setDone(true);
        throw new Error("Failed to Edit product item", err);
      });
  };

  const Delete = (id) => {
    setDone(false);
    ProductsService._deleteProduct(id)
      .then(() => {
        setDone(true);
        const temp = products.filter((item) => {
          return item.id !== id;
        });
        setProducts(temp);
      })
      .catch((err) => {
        setDone(true);
        throw new Error("Failed to delete product item", err);
      });
  };

  const ShowEditModal = (row) => {
    setEditName(row.name);
    setEditId(row.id);
    setEditDescription(row.description);
    setEditPrice(row.price);
    setEditMedia(row.media);
    setModal(true);
  };

  const previewImage = (src) => {
    setIsOpen(true);
    setCurrentImg(src);
  };

  const closeImage = () => {
    setIsOpen(false);
    setCurrentImg(null);
  };

  const handleMediaCallback = (data) => {
    if (data.length) {
      setEditMedia(data);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const itemId = UPLOADED_FILES_NODE_ID;
    const validationResult = checkFile(file, editMedia, itemId);
    if (validationResult.error) {
      enqueueSnackbar(validationResult.message, {
        variant: validationResult.variant
      });
      return;
    }

    setDone(false);
    ProductsService._uploadFile(file, itemId, APP_NAME)
      .then((data) => {
        setIsVideoFile(!!data.mimetype.match(/^video\/.*/));
        setEditMedia([...editMedia, data]);
        setDone(true);
      })
      .catch((err) => {
        setDone(true);
        throw new Error("Failed to Upload File", err);
      });
  };

  const clickHandler = (index) => {
    navigate(`/product/${index}`);
  };

  return (
    <div>
      <Header />
      <MainContainer>
        {!done ? (
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={100}
            width={100}
            className={"loader"}
          />
        ) : (
          <div>
            <h1
              className="order-history-title"
              style={styles.orderHistoryTitle}>
              Your Products
            </h1>
            <div>
              <Modal
                open={modal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box
                  sx={{
                    backgroundColor: "white",
                    textAlign: "center",
                    width: "85%",
                    margin: "auto",
                    marginTop: "10%",
                    padding: "20px"
                  }}>
                  <Typography variant="h5" gutterBottom>
                    Product Information
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required={true}
                        id="name"
                        name="name"
                        label="Name"
                        startadornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        fullWidth
                        variant="outlined"
                        value={editName}
                        onChange={(e) => {
                          setEditName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required={true}
                        id="price"
                        name="price"
                        label="Price"
                        startadornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        fullWidth
                        variant="outlined"
                        value={editPrice}
                        onChange={(e) => {
                          //only positive numbers allowed, and digits
                          if (isNaN(e.target.value) || e.target.value < 0)
                            return;
                          setEditPrice(parseFloat(e.target.value).toFixed(2));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required={true}
                        id="description"
                        name="description"
                        label="Description"
                        startadornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        inputProps={{
                          style: {
                            overflow: "auto",
                            resize: "vertical"
                          }
                        }}
                        value={editDescription}
                        onChange={(e) => {
                          setEditDescription(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        variant="contained"
                        component="label"
                        color="success">
                        Upload Image or Video
                        <input
                          type="file"
                          multiple
                          accept="image/*, video/*"
                          hidden
                          onChange={(e) => handleFileChange(e)}
                        />
                      </Button>
                      <MediaFileView
                        parentCallback={handleMediaCallback}
                        isVideoFile={isVideoFile}
                        mediaInfo={editMedia}></MediaFileView>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={Edit}>
                        Update
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="danger"
                        onClick={() => setModal(false)}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={currentImg}
                onCloseRequest={() => closeImage()}
              />
            )}
            <div className="order-history-main" style={styles.orderHistory}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer style={styles.orderHistoryTable}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    size="small"
                    sx={{
                      "& .MuiTableCell-sizeSmall": {
                        padding: "6px 6px"
                      }
                    }}>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}>
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {products.map((row) => {
                        let imageSource = productImage(row);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}>
                            <TableCell
                              align={columns[0].align}
                              onClick={() => clickHandler(row.id)}>
                              {imageSource ? (
                                <Card sx={{ maxWidth: 200 }}>
                                  <CardMedia
                                    component="img"
                                    image={imageSource}
                                    alt={imageSource}
                                    sx={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      objectFit: "contain",
                                      pt: 0
                                    }}
                                    onClick={() => previewImage(imageSource)}
                                  />
                                </Card>
                              ) : (
                                <IconButton>
                                  <PersonalVideoIcon></PersonalVideoIcon>
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              align={columns[1].align}
                              onClick={() => clickHandler(row.id)}>
                              {row.name}
                            </TableCell>
                            <TableCell
                              align={columns[2].align}
                              onClick={() => clickHandler(row.id)}>
                              {row.price}
                            </TableCell>
                            <TableCell align={columns[3].align}>
                              <IconButton
                                aria-label="delete"
                                onClick={() => Delete(row.id)}>
                                <Tooltip id="delete" title="Delete">
                                  <DeleteIcon />
                                </Tooltip>
                              </IconButton>
                              <IconButton
                                aria-label="edit"
                                onClick={() => ShowEditModal(row)}>
                                <Tooltip id="edit" title="Edit">
                                  <EditIcon />
                                </Tooltip>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        )}
      </MainContainer>
    </div>
  );
}

const styles = {
  orderHistory: {
    display: "flex",
    padding: "25px",
    justifyContent: "center",
    alignItems: "center"
  },
  orderHistoryTable: {
    height: "auto"
  },
  orderHistoryTitle: {
    padding: "25px"
  },
  tableHeaderRow: {
    backgroundColor: "#d3d3d3"
  }
};

export default ProductManagement;
